import { useEffect, useRef, useState } from 'react';
import { Base as BaseColors } from '@/atoms/colors';
import { Tooltip } from '@/molecules/modals';
import { D3Chart, BubbleChart } from '@/organisms/charts';
import Box from '@mui/material/Box';
import { percent } from '@pkg/utils/numbers';
import { Position } from '@/lib/enums';
import { brand } from '@/lib/theme/tokens/palettes';
import { bubbleColor, bubbleGravity } from '../utils';
import MatrixPercentageTitle from './MatrixPercentageTitle';
import MatrixTitle from './MatrixTitle';

/**
 * Matrix box.
 */
const MatrixBox = ({
  entityType,
  height,
  isHighlighted,
  isMuted,
  isSelected,
  metric,
  metricMax,
  metricTotals,
  onClick,
  position,
  props,
  showTitles = true,
  titleHeight,
  onTitleHeightUpdate,
  tags,
  totals,
}) => {
  const { metrics, props: item } = props;
  const tagIds = [...tags.keys()];
  const [data, setData] = useState();
  const percentage = percent(
    metrics[metric] ?? metrics.hours,
    totals[metric] ?? totals.hours,
    0
  );
  const ref = useRef();

  const isTitleBelow =
    position === Position.BOTTOM_LEFT || position === Position.BOTTOM_RIGHT;

  const color = bubbleColor({
    id: item.id,
    isHighlighted,
    isMuted,
    tags,
  });

  const gravityDirection = bubbleGravity(position);

  useEffect(() => {
    if (!props.data) {
      return;
    }

    const data = props.data.filter(({ type }) => type === entityType);

    setData(data);
  }, [JSON.stringify(tagIds), JSON.stringify(totals), metric]);

  return (
    <Tooltip color="secondary" position="top" title={item.subtitle}>
      <Box>
        {!isTitleBelow && showTitles && (
          <MatrixTitle id={item.id} tags={tags} />
        )}
        <Box
          sx={{
            borderRadius: '2px',
            boxShadow: isSelected
              ? `0 0 0 1px ${color}, inset 0 0 0 1px ${color}`
              : `0 0 0 1px ${BaseColors.border.primary}`,
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: isSelected
                ? 'inherit'
                : brand.lightBlue.tints[2],
            },
          }}
        >
          {!isTitleBelow && (
            <MatrixPercentageTitle
              isMuted={isMuted}
              title={item.title}
              percentage={percentage}
              position={Position.TOP}
              titleHeight={titleHeight}
              onUpdateHeight={onTitleHeightUpdate}
            />
          )}
          <Box height={height}>
            {data && (
              <D3Chart
                height={height}
                hideBorder
                id={`${item.id}-bubble-chart`}
              >
                <BubbleChart
                  initialData={data}
                  gravityDirection={gravityDirection}
                  metric={metric}
                  metricMax={metricMax}
                  metricTotals={metricTotals}
                  onClick={onClick}
                  color={color}
                />
              </D3Chart>
            )}
          </Box>
          {isTitleBelow && (
            <MatrixPercentageTitle
              isMuted={isMuted}
              title={item.title}
              percentage={percentage}
              titleHeight={titleHeight}
              onUpdateHeight={onTitleHeightUpdate}
              position={Position.BOTTOM}
            />
          )}
        </Box>
        {isTitleBelow && showTitles && <MatrixTitle id={item.id} tags={tags} />}
      </Box>
    </Tooltip>
  );
};

export default MatrixBox;
