import { useMemo } from 'react';
import { TagCell, TagFilter } from '@/molecules/tableElements';
import { uniqueValueColumn } from '@/organisms/tables/utils';
import { usePropertyContext, useTagContext } from '@/shared/providers';
import { DesignEntity } from '@/lib/enums';

const useGroupColumnDefs = (dimension) => {
  const { propertyList } = usePropertyContext();
  const { groupTagOptions: tagOptions } = useTagContext();

  return useMemo(() => {
    if (!dimension) {
      return;
    }

    return [
      {
        field: 'team.id',
        filter: 'agMultiColumnFilter',
        hide: true,
      },
      {
        chartDataType: 'category',
        field: 'team.name',
        filter: 'agMultiColumnFilter',
        width: 350,
      },
      {
        chartDataType: 'category',
        field: 'team.lead',
        filter: 'agMultiColumnFilter',
      },
      {
        chartDataType: 'series',
        field: 'team.hours',
        filter: 'agNumberColumnFilter',
        hide: true,
        headerName: 'Raw Team Hours',
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        chartDataType: 'series',
        colId: 'team.sumHours',
        filter: 'agNumberColumnFilter',
        headerName: 'Team Hours',
        valueGetter: (params) => {
          return uniqueValueColumn(params, 'team.hours', 'team.id');
        },
      },
      {
        chartDataType: 'excluded',
        cellRenderer: TagCell,
        field: 'team.tags',
        filter: TagFilter,
        filterParams: {
          title: 'Team Tag filter',
          values: tagOptions,
        },
        keyCreator: (params) => {
          return params.value?.map(({ name }) => name)?.join(', ') ?? 'No tags';
        },
        valueFormatter: (params) => {
          if (!params?.value) {
            return;
          }
          return params.value?.map(({ name }) => name)?.join(', ');
        },
      },
      {
        colId: 'team.properties',
        headerName: 'Team Properties',
        children: propertyList
          .filter((property) => property.scope === DesignEntity.GROUP)
          .map((property) => ({
            chartDataType: 'series',
            colId: property.uuid,
            filter: 'agMultiColumnFilter',
            headerName: property.name,
            valueGetter: (params) => {
              const value = params.data?.team?.properties?.[property.uuid];
              return value ?? '';
            },
          })),
      },
      {
        cellDataType: 'date',
        chartDataType: 'date',
        field: 'team.created_at',
        filter: 'agDateColumnFilter',
        headerName: 'Team Created',
        hide: true,
      },
      {
        cellDataType: 'date',
        chartDataType: 'date',
        filter: 'agDateColumnFilter',
        field: 'team.updated_at',
        headerName: 'Team Updated',
        hide: true,
      },
    ].filter((group) => group);
  }, [dimension, JSON.stringify(propertyList)]);
};

export default useGroupColumnDefs;
