import { Fragment, useEffect, useState } from 'react';
import { Base as BaseColor } from '@/atoms/colors';
import { Divider } from '@/atoms/dividers';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import Stack from '@mui/material/Stack';
import { brand, core } from '@/lib/theme/tokens/palettes';
import ScopeOptionsMap from './utils/ScopeOptionsMap';

const ScopeSelector = ({ initialSelected, onClick, view }) => {
  const options = ScopeOptionsMap.get(view);
  const [selected, setSelected] = useState(initialSelected);

  const handleClick = (id) => {
    setSelected(id);
    onClick(id);
  };

  useEffect(() => {
    if (initialSelected !== selected) {
      setSelected(initialSelected);
    }
  }, [initialSelected]);

  return (
    <Stack mx={0.5} sx={{ color: BaseColor.font.primary }}>
      {options.map((option, index) => (
        <Fragment key={option.id}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            height={40}
            my={0.5}
            onClick={() => handleClick(option.id)}
            p={0.5}
            sx={{
              color: selected === option.id ? core.white : 'inherit',
              borderRadius: '2px',
              backgroundColor:
                selected === option.id
                  ? brand.lightBlue.shades[3]
                  : 'transparent',
              '&:hover': {
                cursor: selected === option.id ? 'inherit' : 'pointer',
              },
            }}
          >
            <Paragraph
              size={Size.X_SMALL}
              overrideStyles={{
                mb: 0,
                fontWeight: selected === option.id ? 500 : 400,
                textShadow:
                  selected === option.id
                    ? `0 1px 1px ${brand.lightBlue.shades[6]}77`
                    : 'none',
              }}
            >
              {option.label}
            </Paragraph>
          </Stack>
          {index < options.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Stack>
  );
};

export default ScopeSelector;
