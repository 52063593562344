import { useState, useEffect } from 'react';
import { entityChart } from '@/organisms/charts';
import { useAggregateEntities } from '@/shared/hooks';

const useAggregateChartData = ({ dimension, filter, sort }) => {
  const [chartData, setChartData] = useState();
  const [metricTotals, setMetricTotals] = useState();

  const { entities } = useAggregateEntities({
    order: dimension.order,
    filter,
    sort,
  });

  useEffect(() => {
    if (!entities?.metrics) {
      setChartData([]);
      return;
    }

    const chartData = entityChart({ entities, sort });

    setChartData(chartData);
    setMetricTotals(entities.metrics);
  }, [entities?.metrics, JSON.stringify(sort)]);

  return {
    chartData,
    metricTotals,
  };
};

export default useAggregateChartData;
