const EntityType = Object.freeze({
  ACTIVITY: 'ACTIVITY',
  DESIGN: 'DESIGN',
  GROUP: 'GROUP',
  LEAD: 'LEAD',
  LIVE: 'LIVE',
  MANAGER: 'MANAGER',
  MATRIX: 'MATRIX',
  ORGANISATION: 'ORGANISATION',
  ROLE: 'ROLE',
  SKILL: 'SKILL',
  TAG: 'TAG',
  PERSON: 'PERSON',
  PROPERTY: 'PROPERTY',
  SUPPORTER: 'SUPPORTER',
});

export default EntityType;
