import { useEffect, useState } from 'react';
import { Base as BaseColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { DimensionSelect, MetricTypeSelect } from '@/molecules/chartElements';
import { ActivityDimensions } from '@/molecules/chartElements';
import { ActivityBarChartInformation } from '@/molecules/information';
import { ChartCard } from '@/organisms/cards';
import { D3Chart, HorizontalBarChart } from '@/organisms/charts';
import { useAggregateChartData } from '@/organisms/insights';
import { EntityType } from '@/shared/enums';
import { useEntityNavigation } from '@/shared/hooks';
import { useInsightsContext, useViewModeContext } from '@/shared/providers';
import Stack from '@mui/material/Stack';
import { EntityMetric } from '@/lib/enums';

const ActivitiesBarChart = ({ levelType }) => {
  const [metric, setMetric] = useState('percentage');
  const [dimension, setDimension] = useState(
    ActivityDimensions[levelType]?.[0]
  );
  const [sort, setSort] = useState({
    metric: 'hours',
    type: 'DESC',
  });
  const { filter } = useInsightsContext();
  const { showBudget } = useViewModeContext();
  const { navigateToEntity } = useEntityNavigation();

  const { chartData, metricTotals } = useAggregateChartData({
    dimension,
    filter,
    sort,
  });

  const hasActivities = metricTotals?.activities > 0;

  const handleDimensionChange = (dimension) => {
    setDimension(dimension);
  };

  const handleMetricTypeChange = (metric) => {
    setMetric(metric.id);
    setSort({
      ...sort,
      metric: metric.id === 'percentage' ? 'hours' : metric.id,
    });
  };

  // Handles double click navigation for person rows.
  const handleNavigate = (row) => {
    if (!row) {
      return;
    }

    navigateToEntity({
      type: EntityType.ROLE,
      uuid: row.props?.role_uuid,
    });
  };

  // This effect resets the chart if it's showing metric data and budgets have
  // been switched off.
  useEffect(() => {
    if (!showBudget && metric === EntityMetric.BUDGET) {
      setMetric(EntityMetric.PERCENTAGE);
    }
  }, [showBudget]);

  return (
    <ChartCard
      title="Where time is spent"
      ControlsComponent={
        hasActivities && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <DimensionSelect
              dimensions={ActivityDimensions[levelType]}
              label="Activity by"
              onChange={handleDimensionChange}
            />
            <MetricTypeSelect onChange={handleMetricTypeChange} />
          </Stack>
        )
      }
      InfoComponent={<ActivityBarChartInformation />}
    >
      {hasActivities ? (
        <D3Chart height={400} id="activity-insights" i>
          <HorizontalBarChart
            initialData={chartData}
            metric={metric}
            metricTotals={metricTotals}
            onNavigate={handleNavigate}
            sort={sort}
          />
        </D3Chart>
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          height={400}
          sx={{
            boxShadow: `0 0 0 1px ${BaseColors.border.primary}`,
          }}
        >
          <Paragraph size={Size.LARGE}>No activities were found.</Paragraph>
        </Stack>
      )}
    </ChartCard>
  );
};

export default ActivitiesBarChart;
