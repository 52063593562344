import { TagMatrixChart } from '@/organisms/charts';
import { MatrixPreset } from '@/shared/enums';
import { useScenarioContext, useViewModeContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';
import InsightsTitle from '../InsightsTitle/InsightsTitle';

const PrioritisationInsights = () => {
  const { scenario, snapshotEntityMap } = useScenarioContext();
  const entityType = scenario?.entity?.__type;
  const { id, scope, scopeEntity, showBudget } = useViewModeContext();

  return (
    <Grid container spacing={2} pb={4}>
      <Grid item xs={12}>
        <InsightsTitle
          entityMap={snapshotEntityMap}
          id={id}
          scope={scope}
          scopeEntity={scopeEntity}
        />
      </Grid>
      {scenario && (
        <Grid item xs={12}>
          <TagMatrixChart
            levelType={entityType}
            matrixPreset={MatrixPreset.PRIORITISATION}
            tag={null}
            showBudget={showBudget}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PrioritisationInsights;
