import { cloneElement } from 'react';
import * as Colors from '@/atoms/colors';
import {
  ActivityInsights,
  AlignmentInsights,
  PrioritisationInsights,
  SummaryInsights,
  TagInsights,
} from '@/organisms/insights';
import { InsightsNavigation } from '@/organisms/navigation';
import { ScopeType } from '@/shared/enums';
import { InsightsProvider, useViewModeContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const InsightComponent = Object.freeze({
  [ScopeType.SUMMARY]: <SummaryInsights />,
  [ScopeType.TAGS]: <TagInsights />,
  [ScopeType.ACTIVITIES]: <ActivityInsights />,
  [ScopeType.PRIORITISATION]: <PrioritisationInsights />,
  [ScopeType.ALIGNMENT]: <AlignmentInsights />,
});

const InsightsLayout = () => {
  const { scope } = useViewModeContext();

  return (
    <InsightsProvider>
      <Box
        color={Colors.Base.font.primary}
        height="calc(100vh - 87px)"
        position="relative"
        sx={{
          backgroundColor: Colors.Base.background.secondary,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: 116,
            top: 16,
            width: 320,
          }}
        >
          <InsightsNavigation />
        </Box>
        <Stack
          direction="row"
          height="100%"
          justifyContent="flex-end"
          sx={{
            overflowY: 'scroll',
            scrollBehavior: 'smooth',
          }}
        >
          <Box width="calc(100vw - 453px)" p={2} pb={14}>
            {cloneElement(InsightComponent[scope])}
          </Box>
        </Stack>
      </Box>
    </InsightsProvider>
  );
};

export default InsightsLayout;
