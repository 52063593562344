import getSummarised from '@pkg/entities/tags/get/summarised';
import { Num } from '@pkg/utils';
import { DesignEntity, Visibility } from '@/lib/enums';
import DefaultEntityMetrics from '../enums/DefaultEntityMetrics';
import roundFloatMetrics from './shared/roundFloatMetrics';

export default { derive, apply };

/**
 * @param {Object} library
 * @param {Object} keyed
 * @param {Object} metrics
 */
function derive(library, keyed, metrics) {
  keyed.__keys.groups.forEach((id) => {
    if (!metrics.has(id)) {
      metrics.set(id, structuredClone(DefaultEntityMetrics));
    }

    // normalize props
    const group = keyed.entities.groups[id];
    group.properties = group.properties ?? [];
    group.tags = group.tags ?? [];

    const manager = keyed.entities.roles[group.__manager] ?? null;
    group.__person = keyed.entities.people[manager?.user_uuid] ?? null;

    group.__tags_set = new Set(group.tags);
    group.__tags_summarised = getSummarised(library.tags.tags, group.tags);
    group.__type = DesignEntity.GROUP;

    group.__visibility = group.__visibility ?? Visibility.FULL;
    const isVisible = group.__visibility === Visibility.FULL;

    // root metrics
    const root = metrics.get('*');
    root.total.groups += 1;
    root.visible.groups += isVisible ? 1 : 0;

    // loop through path
    group.__path.entries().forEach(([entityId, { type }]) => {
      if (!metrics.has(entityId)) {
        metrics.set(entityId, structuredClone(DefaultEntityMetrics));
      }

      // setup
      const entityMetrics = metrics.get(entityId);
      const entityPlural = DesignEntity.toPlural(type);
      const entity = keyed.entities[entityPlural][entityId];

      const isSelf = entityId === group.group_uuid;
      const isSpan = entity.__span[DesignEntity.GROUP].has(group.uuid);

      // total
      entityMetrics.managed.total.groups += 1;
      entityMetrics.managed.visible.groups += isVisible ? 1 : 0;

      entityMetrics.self.total.groups += isSelf ? 1 : 0;
      entityMetrics.self.visible.groups += isSelf && isVisible ? 1 : 0;

      entityMetrics.span.total.groups += isSpan ? 1 : 0;
      entityMetrics.span.visible.groups += isSpan && isVisible ? 1 : 0;
    });
  });
}

/**
 * Apply the metrics once everything is derived
 * @param {Object} keyed
 * @param {Object} metrics
 */
function apply(keyed, metrics) {
  keyed.__keys.groups.forEach((id) => {
    const group = keyed.entities.groups[id];
    group.__metrics = roundFloatMetrics(metrics.get(id));
    group.__percentage = null;

    // calculate percentage
    if (Number.isFinite(group.__metrics.self.total.hours)) {
      const totalHours = group.__metrics.self.total.hours;
      const visibleHours = group.__metrics.self.visible.hours;
      group.__percentage = Num.percent(visibleHours, totalHours);
    }

    /** @deprecated */
    group.__total_metrics = group.__metrics.self.total;
    group.__visible_metrics = group.__metrics.self.visible;
  });
}
