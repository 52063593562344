import { Snapshots } from '@pkg/entities';
import { DesignEntity } from '@/lib/enums';

/**
 * @todo re-order roles after archiving
 *
 * @param {Object} snapshot
 * @param {String[]} ids
 * @returns {Object}
 */
export default function archive(snapshot, ids) {
  const keyed = Snapshots.utils.getHierarchy(snapshot);
  const now = Date.now();
  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: {},
      },
      roles: {
        update: {},
        remove: [],
      },
      activities: {
        remove: [],
      },
    },
  };

  // prepare
  const removed = structuredClone(keyed);
  const removeIds = new Set();
  const parentIds = new Map();

  ids.forEach((roleId) => {
    if (Object.hasOwn(keyed.entities.roles, roleId)) {
      const role = keyed.entities.roles[roleId];
      parentIds.set(roleId, role.parent_uuid);

      delete removed.entities.roles[roleId];
      mutation.entities.roles.remove.push(roleId);
      removeIds.add(roleId);

      keyed.entities.roles[roleId].__activities.forEach((activityId) => {
        delete removed.entities.activities[activityId];
        mutation.entities.activities.remove.push(activityId);
      });
    }
  });

  // loop removed roles
  removeIds.forEach((roleId) => {
    const role = keyed.entities.roles[roleId];

    let nextId = parentIds.get(roleId);
    while (removeIds.has(nextId)) {
      nextId = parentIds.get(nextId);
    }

    role.__direct[DesignEntity.ROLE].forEach((uuid) => {
      if (!removeIds.has(uuid)) {
        mutation.entities.roles.update[uuid] = {
          uuid,
          parent_uuid: nextId,
          updated_at: now,
        };
      }
    });

    role.__direct[DesignEntity.GROUP].forEach((uuid) => {
      mutation.entities.groups.update[uuid] = {
        uuid,
        lead_uuid: nextId,
        updated_at: now,
      };
    });
  });

  // cleanup mutation
  Object.keys(mutation.entities).forEach((type) => {
    Object.keys(mutation.entities[type]).forEach((action) => {
      const actions = Object.values(mutation.entities[type][action]);
      mutation.entities[type][action] = actions;
      if (!actions.length) {
        delete mutation.entities[type][action];
      }
    });

    if (!Object.keys(mutation.entities[type]).length) {
      delete mutation.entities[type];
    }
  });

  return mutation;
}
