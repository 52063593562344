import { memo } from 'react';
import MetaTitle from '@/components/MetaTitle';

const App = ({
  pageId = '',
  heading,
  actions,
  breadcrumbs = {},
  fullWidth = false,
  noHeader = false,
  children,
  metaTitle,
  ...props
}) => {

  return (
    <>
      <MetaTitle title={metaTitle} />
      {children}
    </>
  );
};

export default memo(App);
