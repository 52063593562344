import { useEffect, useState } from 'react';
import { Select } from '@/atoms/inputs';
import { NestedGroupSwitch } from '@/molecules/insightElements';
import { List } from '@/molecules/lists';
import { OutlineCard } from '@/organisms/cards';
import { EntityType } from '@/shared/enums';
import { useScenarioContext } from '@/shared/providers';
import { useInsightsContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import InsightMenuItem from '../InsightMenuItem';
import ActivityMenuOption from './ActivityMenuOption';
import buildEntityOptions from './buildEntityOptions';

const EntityOptions = [
  {
    id: EntityType.GROUP,
    label: 'Teams',
  },
  {
    id: EntityType.MANAGER,
    label: 'Managers',
  },
  {
    id: EntityType.ROLE,
    label: 'Roles',
  },
  {
    id: EntityType.ACTIVITY,
    label: 'Activities',
  },
];

const ActivityMenu = ({
  excludeHeight,
  initialEntity = EntityType.GROUP,
  scopeEntity,
  onIdChange,
  onEntityChange,
  selected,
}) => {
  const { scenario, snapshotEntityMap } = useScenarioContext();
  const { updateFilterOptions } = useInsightsContext();
  const entityType = scenario?.entity?.__type;
  const isManager = scenario?.entity?.is_manager;
  const showSelector =
    scenario && (isManager || entityType !== EntityType.ROLE);
  const showSubgroupsSwitch =
    showSelector &&
    scopeEntity !== EntityType.ROLE &&
    scopeEntity !== EntityType.ACTIVITY;
  const reducedHeight = showSelector ? excludeHeight + 196 : excludeHeight + 80;

  const [options, setOptions] = useState([]);
  const [hideNestedEntities, setHideNestedEntities] = useState(false);

  useEffect(() => {
    if (!scenario?.entity?.__type) {
      return;
    }

    const optionEntity = showSelector ? initialEntity : EntityType.ACTIVITY;

    const options = buildEntityOptions(
      scenario,
      snapshotEntityMap,
      optionEntity
    );

    setHideNestedEntities(false);
    setOptions(options);
  }, [
    scenario?.entity?.__uuid,
    scenario?.entity?.uuid,
    scenario?.entity?.__visible_metrics,
    initialEntity,
  ]);

  const handleOptionsChange = (event) => {
    const value = event?.target.checked;
    updateFilterOptions?.({
      hideNestedEntities: value,
    });
    setHideNestedEntities(value);
  };

  return (
    <>
      {showSelector && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Select
            initialValue={EntityOptions.find(({ id }) => id === initialEntity)}
            minWidth={130}
            onChange={onEntityChange}
            options={EntityOptions}
            OptionComponent={<ActivityMenuOption />}
          />
          {showSubgroupsSwitch && (
            <NestedGroupSwitch
              readOnly={!selected || selected === 'ALL'}
              entity={scopeEntity}
            />
          )}
        </Stack>
      )}
      {options.length > 0 && (
        <OutlineCard padding={0} margin={0}>
          <Box
            sx={{
              maxHeight: `calc(100vh - ${reducedHeight}px)`,
              overflowY: 'auto',
            }}
          >
            <List
              ListItem={
                <InsightMenuItem
                  scopeEntity={
                    scenario?.entity?.__type === EntityType.ROLE && !isManager
                      ? EntityType.ACTIVITY
                      : scopeEntity
                  }
                  allowDoubleClick
                />
              }
              items={options}
              onClick={onIdChange}
              selected={selected ?? options[0].id}
              showDividers
            />
          </Box>
        </OutlineCard>
      )}
    </>
  );
};

export default ActivityMenu;
