import { useMemo } from 'react';
import * as Tags from '../tags';
import * as Activities from './activities';
import * as FlowTemplates from './flowTemplates';
import * as Properties from './properties';
import * as Skills from './skills';

/**
 * @param {Function} get
 * @returns {*}
 */
export default function useLibrary(get = (state) => state) {
  const activities = Activities.useStore();
  const flowTemplates = FlowTemplates.useStore();
  const properties = Properties.useStore();
  const tags = Tags.useStore();
  const skills = Skills.useStore();
  const library = useMemo(() => {
    return Object.freeze({
      activities,
      flowTemplates,
      properties,
      skills,
      tags,
      isLoading:
        activities.isLoading ||
        flowTemplates.isLoading ||
        properties.isLoading ||
        tags.isLoading ||
        skills.isLoading,
      updatedAt: new Date(),
    });
  }, [activities, flowTemplates, properties, skills, tags]);

  return useMemo(() => get(library), [library]);
}
