import shortuuid from '@pkg/uuid';
import config from '@/lib/config';

/**
 * @param {Object} role
 * @param {Object[]} activities
 * @param {Object} options
 * @returns {Object}
 */
export default function copy(role, activities, options) {
  const { target, copyTags, title, newId } = options;
  const now = Date.now();

  const roleInput = {
    uuid: newId ?? shortuuid.generate(),
    group_uuid: target ?? null,
    parent_uuid: null,
    user_uuid: role.user_uuid,
    title: title || config.DEFAULT_ROLE_TITLE,
    fte: role.fte,
    properties: role.properties ?? [],
    skills: role.skills ?? [],
    tags: copyTags ? role.tags : [],
    order: 0,
    created_at: now,
    updated_at: now,
  };

  if (Number.isFinite(role.budget)) {
    roleInput.budget = 0;
  }

  const activitiesInput = activities?.map((activity) => ({
    ...activity,
    uuid: shortuuid.generate(),
    owner_uuid: roleInput.uuid,
    tags: copyTags ? activity.tags : [],
    created_at: now,
    updated_at: now,
  }));

  return {
    created_at: now,
    entities: {
      groups: {
        update: [{ uuid: target ?? null, updated_at: now }],
      },
      roles: {
        create: [roleInput],
      },
      activities: {
        create: activitiesInput,
      },
    },
  };
}
