import { useEffect, useState } from 'react';
import { ChartTooltip } from '@/molecules/chartElements';
import { EntityType } from '@/shared/enums';
import renderSimulation from './renderSimulation';

const BubbleChart = ({
  color,
  gravityDirection,
  height,
  initialData,
  metric,
  metricMax,
  metricTotals,
  onClick,
  width,
}) => {
  const [data, setData] = useState();

  useEffect(() => {
    if (!width || !initialData) {
      return;
    }

    const results = renderSimulation({
      alignment: gravityDirection,
      dataset: initialData.filter((item) => item.type === EntityType.ACTIVITY),
      height,
      metric,
      metricMax,
      metricTotal: metricTotals?.[metric] ?? 0,
      width,
    });

    setData(results);
  }, [width, initialData]);

  return (
    data?.length > 0 && (
      <g id="bubble-chart" className="bubbles">
        {data.map((result) => (
          <ChartTooltip
            key={result.id}
            item={result}
            maxWidth={500}
            metric={metric}
            metricTotals={metricTotals}
          >
            <circle
              onClick={() => onClick?.(result)}
              r={result.value}
              cx={result.x}
              cy={result.y}
              fill={color}
            />
          </ChartTooltip>
        ))}
      </g>
    )
  );
};

export default BubbleChart;
