import { Snapshots } from '@pkg/entities';
import { Collections, Num } from '@pkg/utils';
import { SkillLevel } from '@/lib/enums';
import archive from './archive';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String[]} input.sourceIds
 * @param {String} input.targetId
 * @returns {Object}
 */
export default function merge(snapshot, { sourceIds, targetId }) {
  const keyed = Snapshots.utils.getKeyed(snapshot).entities;
  const entities = structuredClone(snapshot.entities);

  const now = Date.now();
  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
      },
      roles: {
        update: [],
        remove: [],
      },
      activities: {
        update: [],
        remove: [],
      },
    },
  };

  // run archive to handle hierarchy
  const archived = archive(snapshot, sourceIds);
  mutation.entities.groups.update = archived.entities.groups?.update ?? [];
  mutation.entities.roles.update = archived.entities.roles?.update ?? [];
  mutation.entities.roles.remove = archived.entities.roles?.remove ?? [];

  // setup new/splice existing update object
  const updateIndex = mutation.entities.roles.update.findIndex((role) => {
    return role.uuid === targetId;
  });

  const update = mutation.entities.roles.update[updateIndex] ?? {
    uuid: targetId,
    updated_at: now,
  };

  const isUpdating = updateIndex >= 0;
  if (isUpdating) {
    mutation.entities.roles.update.splice(updateIndex, 1);
  }

  // prepare data
  const activityIds = new Set(archived.entities.activities?.remove || []);
  const roleIds = new Set(archived.entities.roles.remove);

  const target = keyed.roles[targetId];
  update.fte = target.fte;
  update.properties = new Map(
    target.properties.map(({ key, value }) => [key, value])
  );
  update.skills = new Map(
    target.skills.map(({ uuid, level }) => [uuid, level])
  );
  update.tags = new Set(target.tags);

  // update target role
  let isFteDifferent = false;
  let isPropertiesDifferent = false;
  let isSkillsDifferent = false;
  let isTagsDifferent = false;
  let isDifferent = isUpdating || activityIds.size > 0;

  roleIds.forEach((id) => {
    const role = keyed.roles[id];

    if (Number.isFinite(role.fte) && role.fte > 0) {
      update.fte += role.fte;
      isFteDifferent = true;
      isDifferent = true;
    }

    role.properties?.forEach(({ key, value }) => {
      if (!update.properties.get(key)) {
        update.properties.set(key, value);
        isPropertiesDifferent = true;
        isDifferent = true;
      }
    });

    role.skills?.forEach(({ uuid, level }) => {
      const current = update.skills.get(uuid) ?? null;
      const highest = SkillLevel.keepHighest(current, level);

      if (current !== highest) {
        update.skills.set(uuid, level);
        isSkillsDifferent = true;
        isDifferent = true;
      }
    });

    role.tags?.forEach((id) => {
      if (!update.tags.has(id)) {
        update.tags.add(id);
        isTagsDifferent = true;
        isDifferent = true;
      }
    });
  });

  // modify mutation
  if (isDifferent) {
    if (isFteDifferent) {
      update.fte = Num.roundFloat(update.fte);
    } else {
      delete update.fte;
    }

    if (isPropertiesDifferent) {
      update.properties = Array.from(update.properties).map(([key, value]) => ({
        key,
        value,
      }));
    } else {
      delete update.properties;
    }

    if (isSkillsDifferent) {
      update.skills = Array.from(update.skills).map(([uuid, level]) => ({
        uuid,
        level,
      }));
    } else {
      delete update.skills;
    }

    if (isTagsDifferent) {
      update.tags = Array.from(update.tags);
    } else {
      delete update.tags;
    }

    mutation.entities.roles.update.push(update);
  }

  // update activities
  const targetActivities = Collections.where(
    entities.activities,
    'owner_uuid',
    targetId
  );

  let order = targetActivities.length;
  activityIds.forEach((uuid) => {
    const activity = keyed.activities[uuid];
    const update = { uuid, owner_uuid: targetId, updated_at: now };
    if (activity.order !== order) {
      update.order = order;
    }

    mutation.entities.activities.update.push(update);
    order += 1;
  });

  // remove unused mutations
  Object.keys(mutation.entities).forEach((type) => {
    Object.keys(mutation.entities[type]).forEach((action) => {
      if (!mutation.entities[type][action].length) {
        delete mutation.entities[type][action];
      }
    });

    if (!Object.keys(mutation.entities[type]).length) {
      delete mutation.entities[type];
    }
  });

  return mutation;
}
