import { useMemo } from 'react';
import {
  DimensionType,
  SkillCell,
  SkillFilter,
  TagCell,
  TagFilter,
} from '@/molecules/tableElements';
import { uniqueValueColumn } from '@/organisms/tables/utils';
import {
  usePropertyContext,
  useSkillContext,
  useTagContext,
} from '@/shared/providers';
import { currency } from '@pkg/utils/numbers';
import { DesignEntity } from '@/lib/enums';

const useRoleColumnDefs = ({ dimension, showBudget }) => {
  const { propertyList } = usePropertyContext();
  const { skillMap, skillList } = useSkillContext();
  const { entityTagMap } = useTagContext();
  const tagOptions = entityTagMap?.get(DesignEntity.ROLE);

  return useMemo(() => {
    if (!dimension) {
      return;
    }

    return [
      {
        field: 'role.id',
        filter: 'agMultiColumnFilter',
        hide: true,
      },
      {
        chartDataType: 'category',
        field: 'role.title',
        filter: 'agMultiColumnFilter',
        width: 400,
      },
      showBudget && {
        field: 'role.budget',
        filter: 'agNumberColumnFilter',
        hide: true,
        headerName: 'Raw Role Hours',
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      showBudget && {
        chartDataType: 'series',
        colId: 'role.sumBudget',
        filter: 'agNumberColumnFilter',
        headerName: 'Role Budget',
        valueGetter: (params) => {
          return uniqueValueColumn(params, 'role.budget', 'role.id');
        },
        valueFormatter: ({ value }) => currency(value),
      },
      {
        chartDataType: 'category',
        field: 'role.assignee',
        filter: 'agMultiColumnFilter',
      },
      dimension !== DimensionType.ROLE_SKILLS && {
        chartDataType: 'excluded',
        cellRenderer: SkillCell,
        field: 'role.skills',
        filter: SkillFilter,
        filterParams: {
          title: 'Role Skill filter',
          values: skillList,
        },
        keyCreator: ({ value }) => {
          return value
            ?.map(({ name, level }) => `${name} (${level?.toLowerCase()})`)
            .join(', ');
        },
        valueFormatter: ({ value }) => {
          if (!value) {
            return;
          }

          return value
            ?.map(({ name, level }) => `${name} (${level?.toLowerCase()})`)
            .join(', ');
        },
        width: 400,
      },
      {
        field: 'role.activityHours',
        filter: 'agNumberColumnFilter',
        hide: true,
        headerName: 'Raw Role Hours',
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        chartDataType: 'series',
        colId: 'role.sumActivityHours',
        filter: 'agNumberColumnFilter',
        headerName: 'Role Hours',
        valueGetter: (params) => {
          return uniqueValueColumn(params, 'role.activityHours', 'role.id');
        },
      },
      {
        field: 'role.hours',
        filter: 'agNumberColumnFilter',
        hide: true,
        headerName: 'Raw Role Hours',
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        chartDataType: 'series',
        colId: 'role.sumHours',
        filter: 'agNumberColumnFilter',
        headerName: 'Contracted Hours',
        valueGetter: (params) => {
          return uniqueValueColumn(params, 'role.hours', 'role.id');
        },
      },
      {
        field: 'role.fte',
        filter: 'agNumberColumnFilter',
        hide: true,
        headerName: 'Raw FTE',
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        chartDataType: 'series',
        colId: 'role.sumFte',
        filter: 'agNumberColumnFilter',
        headerName: 'Role FTE',
        valueGetter: (params) => {
          return uniqueValueColumn(params, 'role.fte', 'role.id');
        },
      },
      {
        field: 'role.isManager',
        headerName: 'Role type',
      },
      {
        field: 'role.managedFte',
        filter: 'agNumberColumnFilter',
        headerName: 'Raw Managed FTE',
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        chartDataType: 'series',
        field: 'role.sumManagedFte',
        filter: 'agNumberColumnFilter',
        headerName: 'Managed FTE',
        valueGetter: (params) => {
          return uniqueValueColumn(params, 'role.managedFte', 'role.id');
        },
      },
      {
        field: 'role.managedRoles',
        filter: 'agNumberColumnFilter',
        headerName: 'Raw Managed Roles',
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        chartDataType: 'series',
        field: 'role.sumManagedRoles',
        filter: 'agNumberColumnFilter',
        headerName: 'Managed Roles',
        valueGetter: (params) => {
          return uniqueValueColumn(params, 'role.managedRoles', 'role.id');
        },
      },
      {
        field: 'role.span',
        filter: 'agNumberColumnFilter',
        headerName: 'Raw Role Span',
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        chartDataType: 'series',
        field: 'role.sumSpan',
        filter: 'agNumberColumnFilter',
        headerName: 'Span',
        valueGetter: (params) => {
          return uniqueValueColumn(params, 'role.span', 'role.id');
        },
      },
      {
        field: 'role.layers',
        filter: 'agNumberColumnFilter',
        headerName: 'Raw Role Layers',
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        chartDataType: 'series',
        field: 'role.sumLayers',
        filter: 'agNumberColumnFilter',
        headerName: 'Role Layers',
        valueGetter: (params) => {
          return uniqueValueColumn(params, 'role.layers', 'role.id');
        },
      },
      {
        field: 'role.layer',
        filter: 'agNumberColumnFilter',
        headerName: 'Raw Role Layer',
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        chartDataType: 'series',
        field: 'role.sumLayer',
        filter: 'agNumberColumnFilter',
        headerName: 'Role Layer',
        valueGetter: (params) => {
          return uniqueValueColumn(params, 'role.layer', 'role.id');
        },
      },
      {
        field: 'role.depth',
        filter: 'agNumberColumnFilter',
        headerName: 'Raw Role Depth',
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        chartDataType: 'series',
        field: 'role.sumDepth',
        filter: 'agNumberColumnFilter',
        headerName: 'Role Depth',
        valueGetter: (params) => {
          return uniqueValueColumn(params, 'role.depth', 'role.id');
        },
      },
      {
        field: 'role.childRoles',
        filter: 'agNumberColumnFilter',
        headerName: 'Raw Child Roles',
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        chartDataType: 'series',
        field: 'role.sumChildRoles',
        filter: 'agNumberColumnFilter',
        headerName: 'Child Roles',
        valueGetter: (params) => {
          return uniqueValueColumn(params, 'role.childRoles', 'role.id');
        },
      },
      {
        chartDataType: 'excluded',
        cellRenderer: TagCell,
        field: 'role.tags',
        filter: TagFilter,
        filterParams: {
          title: 'Role Tag filter',
          values: tagOptions,
        },
        keyCreator: (params) => {
          return params.value?.map(({ name }) => name)?.join(', ') ?? 'No tags';
        },
        valueFormatter: (params) => {
          if (!params?.value) {
            return;
          }
          return params.value?.map(({ name }) => name)?.join(', ');
        },
      },
      {
        colId: 'role.properties',
        headerName: 'Role Properties',
        children: propertyList
          .filter((property) => property.scope === DesignEntity.ROLE)
          .map((property) => ({
            chartDataType: 'series',
            colId: property.uuid,
            filter: 'agMultiColumnFilter',
            headerName: property.name,
            valueGetter: (params) => {
              const value = params.data?.role?.properties?.[property.uuid];
              return value ?? '';
            },
          })),
      },
      {
        cellDataType: 'date',
        chartDataType: 'date',
        field: 'role.created_at',
        filter: 'agDateColumnFilter',
        headerName: 'Role Created',
        hide: true,
      },
      {
        cellDataType: 'date',
        chartDataType: 'date',
        field: 'role.updated_at',
        filter: 'agDateColumnFilter',
        headerName: 'Role Updated',
        hide: true,
      },
    ].filter((column) => column);
  }, [skillMap, JSON.stringify(tagOptions), dimension, showBudget]);
};

export default useRoleColumnDefs;
