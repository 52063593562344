import { Base as BaseColors } from '@/atoms/colors';
import { ChartRowType } from '@/shared/enums';
import { brand, core } from '@/lib/theme/tokens/palettes';

const RowColors = Object.freeze({
  [ChartRowType.ACTIVITY]: {
    bar: {
      backgroundColor: brand.grey.main,
      borderColor: brand.grey.shades[0],
    },
    label: {
      color: core.white,
      boxShadow: `0 0 0 1px ${core.white}`,
      backgroundColor: brand.grey.main,
    },
    group: {
      backgroundColor: brand.grey.main,
    },
  },
  [ChartRowType.ROLE]: {
    bar: {
      backgroundColor: brand.teal.main,
      borderColor: brand.teal.shades[0],
    },
    label: {
      color: core.white,
      backgroundColor: brand.teal.main,
      boxShadow: `0 0 0 1px ${core.white}`,
      borderColor: core.white,
    },
    group: {
      backgroundColor: brand.teal.main,
    },
  },
  [ChartRowType.GROUP]: {
    bar: {
      backgroundColor: brand.blue.main,
      borderColor: brand.blue.shades[0],
    },
    label: {
      color: core.white,
      backgroundColor: brand.blue.main,
      boxShadow: `0 0 0 1px ${core.white}`,
      borderColor: core.white,
    },
    group: {
      backgroundColor: brand.blue.main,
    },
  },
  [ChartRowType.PERSON]: {
    bar: {
      backgroundColor: brand.lightBlue.shades[3],
      borderColor: brand.lightBlue.shades[4],
    },
    label: {
      color: core.white,
      backgroundColor: core.black,
      boxShadow: `0 0 0 1px ${core.white}`,
      borderColor: core.white,
    },
    group: {
      backgroundColor: brand.lightBlue.shades[0],
    },
  },
  [ChartRowType.TAG]: {
    bar: {
      backgroundColor: brand.lightBlue.shades[3],
      borderColor: brand.lightBlue.shades[4],
    },
    label: {
      color: BaseColors.font.primary,
      boxShadow: 'transparent',
      backgroundColor: brand.lightBlue.tints[6],
    },
    group: {
      backgroundColor: brand.lightBlue.shades[0],
    },
  },
});

export default RowColors;
