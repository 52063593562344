import { Snapshots } from '@pkg/entities';
import { Collections } from '@pkg/utils';
import { Property, Visibility } from '@/lib/enums';
import { compare } from '../../shared/filters';
import { get } from '@pkg/utils/objects';

function getIdentifierValue(entity, condition) {
  if (condition.metricProperty) {
    return entity[condition.metricProperty][condition.identifier];
  }

  return condition.identifier.startsWith(Property.PROPERTIES)
    ? getPropertyValue(entity[Property.PROPERTIES], condition)
    : get(entity, condition.identifier);
}

function getPropertyValue(array, condition) {
  const [, propertyId] = condition.identifier.split(':');
  const keyedArray = Collections.keyBy(array);
  return keyedArray[propertyId]?.value;
}

export default function apply(snapshot, conditions = {}, peopleSkillMap) {
  if (!snapshot?.entities) {
    return snapshot;
  }

  // bypass when no conditions
  if (Object.values(conditions).every((entities) => entities.length === 0)) {
    return { ...snapshot, __visiblity: Visibility.FULL };
  }

  const filteredSet = {
    groups: new Set(),
    roles: new Set(),
    activities: new Set(),
    people: new Set(),
  };

  const filteredMap = {
    groups: {},
    roles: {},
    activities: {},
    people: {} /** @todo implement */,
  };

  const roleActivitiesMap = {};
  const groupRolesMap = {};

  console.time('Snapshots.conditions.apply');
  const keyedEntities = Snapshots.utils.getKeyed(snapshot).entities;
  const entities = snapshot.entities;
  const entityOrder = ['groups', 'people', 'roles', 'activities'];
  const entityConditions = conditions;

  // Set up initial filtered set (run through conditions)
  console.time('Snapshots.conditions.initial.filteredSet');
  entityOrder.forEach((entityKey) => {
    const singleEntities = entities[entityKey];
    singleEntities.forEach((entity) => {
      filteredMap[entityKey][entity.uuid] = entity;
      const mutableEntity = { ...entity };

      // # perf - don't loop through roles whose groups and people are already
      // filtered
      if (entityKey === 'roles') {
        if (
          conditions.people?.length > 0 &&
          !filteredSet.people.has(entity.user_uuid)
        ) {
          return;
        }
        const groupExists = Boolean(keyedEntities.groups[entity.group_uuid]);
        if (groupExists && !filteredSet.groups.has(entity.group_uuid)) {
          return;
        }
      }

      // # perf - don't loop through activities without role
      if (
        (entityKey === 'activities' &&
          !filteredSet.roles.has(entity.owner_uuid)) ||
        entity.disabled_at
      ) {
        return;
      }

      if (entityKey === 'people' && peopleSkillMap?.size) {
        const skills = peopleSkillMap?.get(entity.uuid);
        if (skills?.size) {
          mutableEntity.skills = [...skills.entries()].map(([uuid, level]) => {
            return { uuid, level };
          });
        }
      }

      // #perf - if one fails, all failed (faster than every)
      const failed = entityConditions[entityKey].some((c) => {
        const identifierValue = getIdentifierValue(mutableEntity, c);
        return !compare(c.relative, identifierValue, c.value);
      });

      if (failed) {
        return;
      }

      //  construct a map of role activities and group roles for later usage
      if (entityKey === 'groups') {
        groupRolesMap[entity.uuid] = [];
      }

      if (entityKey === 'roles') {
        roleActivitiesMap[entity.uuid] = [];
        groupRolesMap[entity.group_uuid]?.push(entity);
      }

      if (entityKey === 'activities') {
        roleActivitiesMap[entity.owner_uuid].push(entity);
      }

      filteredSet[entityKey].add(entity.uuid);
    });
  });
  console.timeEnd('Snapshots.conditions.initial.filteredSet');

  const hasGroupConditions = entityConditions.groups?.length;
  const hasPeopleConditions = entityConditions.people?.length;
  const hasRoleConditions = entityConditions.roles?.length;
  const hasActivityConditions = entityConditions.activities?.length;

  // clean up filtered set by removing parents/children that are filtered
  filteredSet.activities.forEach((a) => {
    // remove activities inside of non filtered roles
    const activityRoleId = filteredMap.activities[a].owner_uuid;
    if (!filteredSet.roles.has(activityRoleId)) {
      filteredSet.activities.delete(a);
    }
  });

  filteredSet.roles.forEach((r) => {
    // remove roles inside of non filtered groups
    const roleGroupUuid = filteredMap.roles[r].group_uuid;
    if (hasGroupConditions && !filteredSet.groups.has(roleGroupUuid)) {
      filteredSet.roles.delete(r);
    }

    // remove roles with no activities if activity condition set
    const roleActivities = roleActivitiesMap[r];
    if (roleActivities.length === 0 && hasActivityConditions) {
      filteredSet.roles.delete(r);
    }

    // remove roles that have no filtered activities
    const valid = roleActivities.some(
      (a) => a.owner_uuid === r && filteredSet.activities.has(a.uuid)
    );
    if (!valid && roleActivities.length > 0) {
      filteredSet.roles.delete(r);
    }
  });

  filteredSet.groups.forEach((g) => {
    // remove groups with no roles if role or activity conditions set
    const groupRoles = groupRolesMap[g];
    if (
      groupRoles.length === 0 &&
      (hasPeopleConditions || hasRoleConditions || hasActivityConditions)
    ) {
      filteredSet.groups.delete(g);
    }

    // remove groups that have no filtered roles
    const valid = groupRoles.some(
      (r) => r.group_uuid === g && filteredSet.roles.has(r.uuid)
    );

    if (!valid && groupRoles.length > 0) {
      filteredSet.groups.delete(g);
    }
  });

  // Set the visibility property for all filtered entities
  for (const [entityKey, singleEntities] of Object.entries(entities)) {
    for (const entity of singleEntities) {
      entity.__visibility = filteredSet[entityKey].has(entity.uuid)
        ? Visibility.FULL
        : Visibility.NONE;
    }
  }

  const filtered = {
    ...snapshot,
    /** @todo base snapshot visibility off something else */
    __visibility: Visibility.FULL,
  };

  console.timeEnd('Snapshots.conditions.apply');
  return filtered;
}
