const ChartRowType = Object.freeze({
  ACTIVITY: 'ACTIVITY',
  GROUP: 'GROUP',
  PERSON: 'PERSON',
  ROLE: 'ROLE',
  SEPARATOR: 'SEPARATOR',
  TAG: 'TAG',
  TITLE: 'TITLE',
});

export default ChartRowType;
