import { EntityType } from '@/shared/enums';

export default Object.freeze({
  [EntityType.ACTIVITY]: EntityType.ACTIVITY,
  [EntityType.DESIGN]: EntityType.ORGANISATION,
  [EntityType.GROUP]: 'TEAM',
  [EntityType.LEAD]: 'TEAM LEAD',
  [EntityType.LIVE]: EntityType.LIVE,
  [EntityType.ORGANISATION]: EntityType.ORGANISATION,
  [EntityType.ROLE]: EntityType.ROLE,
  [EntityType.SKILL]: EntityType.SKILL,
  [EntityType.TAG]: EntityType.TAG,
  [EntityType.PERSON]: EntityType.PERSON,
  [EntityType.PROPERTY]: EntityType.PROPERTY,
  [EntityType.SUPPORTER]: EntityType.SUPPORTER,
});

export const EntityPluralName = Object.freeze({
  [EntityType.ACTIVITY]: 'ACTIVITIES',
  [EntityType.DESIGN]: 'ORGANISATIONS',
  [EntityType.GROUP]: 'TEAMS',
  [EntityType.LEAD]: 'TEAM LEADS',
  [EntityType.LIVE]: EntityType.LIVE,
  [EntityType.ORGANISATION]: 'ORGANISATIONS',
  [EntityType.ROLE]: 'ROLES',
  [EntityType.SKILL]: 'SKILLS',
  [EntityType.TAG]: 'TAGS',
  [EntityType.PERSON]: 'PEOPLE',
  [EntityType.PROPERTY]: 'PROPERTIES',
  [EntityType.SUPPORTER]: 'SUPPORTERS',
});
