import { Snapshots } from '@pkg/entities';
import { Collections } from '@pkg/utils';

/**
 * @param {Object} snapshot
 * @param {Object[]} input
 * @returns {Array}
 */
export default function add(snapshot, input) {
  const now = Date.now();
  const [activity] = input;
  const activityMutation = Snapshots.reducers.activities.add(snapshot, input);

  const entities = snapshot.entities;
  const role = Collections.findById(entities.roles, activity.owner_uuid);

  const newActivity = activityMutation.entities.activities.create[0];
  const roleActivities = Collections.where(
    entities.activities,
    'owner_uuid',
    role.uuid
  );

  const updatedSnapshot = {
    entities: {
      groups: [],
      roles: [{ ...role, updated_at: now }],
      activities: [...roleActivities, newActivity],
    },
  };

  return {
    uuid: role.__template_uuid,
    snapshot: updatedSnapshot,
  };
}
