import { useRef, useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Paragraph } from '@/atoms/typography';
import { EntitiesTable, MatrixBox } from '@/organisms/charts';
import {
  ActivitySuggestions,
  isPrioritisationMatrix,
} from '@/organisms/insights';
import { EntityType } from '@/shared/enums';
import { MatrixPreset } from '@/shared/enums';
import { useScenarioContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Position } from '@/lib/enums';

const topPositions = [Position.TOP_LEFT, Position.TOP_RIGHT];
const bottomPositions = [Position.BOTTOM_LEFT, Position.BOTTOM_RIGHT];

const QuadrantMatrix = ({
  entityType,
  chartData,
  height = 400,
  matrixPreset,
  metricMax,
  metricTotals,
  metric,
  showEntitiesTable = true,
  tags,
}) => {
  const ref = useRef();
  const [focusItem, setFocusItem] = useState();
  const [selected, setSelected] = useState(Position.TOP_RIGHT);
  const { scenario } = useScenarioContext();
  const [titleHeight, setTitleHeight] = useState(0);

  const isOrganisation = scenario?.entity?.__type === EntityType.ORGANISATION;
  const showSuggestions = !tags?.size
    ? !isOrganisation
    : !isOrganisation && isPrioritisationMatrix(tags);

  const [suggestMode, setSuggestMode] = useState();

  const handleItemFocus = (item) => {
    setFocusItem(item.id);
  };

  const handleSelection = (position) => {
    setSelected(position);
  };

  const handleSuggest = () => {
    setSuggestMode(true);
  };

  const handleTitleHeight = (height) => {
    if (height > titleHeight) {
      setTitleHeight(height);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={showEntitiesTable ? 7 : 12}>
        <Box ref={ref}>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
            spacing={1}
            mb={1}
          >
            {topPositions.map((position) => (
              <div
                key={position}
                onClick={() => handleSelection?.(position)}
                style={{ width: '50%' }}
              >
                <MatrixBox
                  entityType={entityType}
                  height={height / 2}
                  isSelected={selected === position}
                  isHighlighted={
                    position === Position.TOP_RIGHT && matrixPreset
                  }
                  metricMax={metricMax}
                  metricTotals={metricTotals}
                  metric={metric}
                  onClick={handleItemFocus}
                  onTitleHeightUpdate={handleTitleHeight}
                  position={position}
                  props={chartData.matrixMap.get(position)}
                  tags={tags}
                  titleHeight={titleHeight}
                  totals={chartData.metrics}
                />
              </div>
            ))}
          </Stack>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={1}
            mb={1}
          >
            {bottomPositions.map((position) => (
              <div
                key={position}
                onClick={() => handleSelection?.(position)}
                style={{ width: '50%' }}
              >
                <MatrixBox
                  entityType={entityType}
                  height={height / 2}
                  isHighlighted={
                    position === Position.BOTTOM_LEFT &&
                    matrixPreset === MatrixPreset.ALIGNMENT
                  }
                  isSelected={selected === position}
                  metricMax={metricMax}
                  metricTotals={metricTotals}
                  metric={metric}
                  onClick={handleItemFocus}
                  onTitleHeightUpdate={handleTitleHeight}
                  position={position}
                  props={chartData.matrixMap.get(position)}
                  tags={tags}
                  titleHeight={titleHeight}
                  totals={chartData.metrics}
                />
              </div>
            ))}
          </Stack>
        </Box>
        {showSuggestions && !suggestMode && (
          <Box>
            <Paragraph overrideStyles={{ my: 2 }}>
              Want to see how we can help you stop, reduce or reallocate low
              importance and low energy activities?
            </Paragraph>
            <Button
              color="secondary"
              label="View suggestions"
              onClick={handleSuggest}
            />
          </Box>
        )}
      </Grid>
      {showEntitiesTable && (
        <Grid item xs={5}>
          <EntitiesTable
            initialData={chartData?.matrixMap?.get(selected)?.data}
            metrics={chartData?.matrixMap?.get(selected)?.metrics}
            selected={chartData?.matrixMap?.get(selected)?.props}
            chartRef={ref}
            metric={metric}
            focusItem={focusItem}
          />
        </Grid>
      )}
      {suggestMode && (
        <Grid item xs={12} sx={{ textAlign: 'left' }}>
          <ActivitySuggestions
            suggestMode={suggestMode}
            setSuggestMode={setSuggestMode}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default QuadrantMatrix;
