import { cloneElement, useEffect, useState } from 'react';
import { Form as FormColor } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { SquareBackgroundIcon } from '@/atoms/icons';
import { SelectOption } from '@/atoms/inputs';
import { outlineInputSizeProps } from '@/atoms/inputs/utils';
import { Paragraph } from '@/atoms/typography';
import { Popover } from '@/molecules/modals';
import TickIcon from '@mui/icons-material/CheckRounded';
import DownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Position } from '@/lib/enums';
import { core } from '@/lib/theme/tokens/palettes';

const ListOptions = ({
  options,
  OptionComponent,
  onClose,
  onClick,
  sizeProps,
  optionLabel,
  minWidth,
  selected,
}) => {
  const handleClick = (event, option) => {
    onClose?.();
    onClick?.(event, option);
  };

  return (
    <Box minWidth={minWidth}>
      {options.map((option) => (
        <SelectOption
          key={option.id}
          onClick={(event) => handleClick(event, option)}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            {OptionComponent ? (
              cloneElement(OptionComponent, { ...option })
            ) : (
              <Box sx={{ fontSize: sizeProps.fontSize }}>
                {option[optionLabel]}
              </Box>
            )}
            {option.id === selected.id && (
              <TickIcon sx={{ height: 22, width: 22 }} />
            )}
          </Stack>
        </SelectOption>
      ))}
    </Box>
  );
};

const Select = ({
  color = 'secondary',
  initialValue,
  label,
  minWidth = 140,
  width,
  onChange,
  options,
  optionLabel,
  OptionComponent,
  size = Size.MEDIUM,
}) => {
  const [selected, setSelected] = useState(initialValue);
  const sizeProps = outlineInputSizeProps(size);

  const handleChange = (event, option) => {
    setSelected(option);
    onChange?.(option);
  };

  const colors = FormColor[color];

  // Handles live updating of the select field when receiving upstream changes.
  useEffect(() => {
    const initialId = initialValue?.uuid ?? initialValue?.id;
    const selectedId = selected?.uuid ?? selected?.id;

    if (initialId !== selectedId) {
      setSelected(initialValue);
    }
  }, [initialValue]);

  return (
    <Popover
      Component={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          px={0.5}
          py={optionLabel ? 0 : 0.5}
          width={width ?? 'auto'}
          minWidth={minWidth ?? width ?? 'auto'}
          sx={{
            boxShadow: `inset 0 0 0 1px ${colors.normal.borderColor}`,
            borderRadius: '4px',
            '&:hover': {
              cursor: 'pointer',
              boxShadow: `inset 0 0 0 1px ${colors.hover.borderColor}`,
            },
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: 'calc(100% - 38px)',
            }}
          >
            {label && (
              <Box
                sx={{
                  position: 'absolute',
                  top: -10,
                  left: 0.5,
                  background: core.white,
                  borderRadius: '2px',
                  px: 0.5,
                }}
              >
                <Paragraph
                  size={Size.X_SMALL}
                  overrideStyles={{
                    mb: 0,
                    fontSize: '0.675rem',
                  }}
                >
                  {label}
                </Paragraph>
              </Box>
            )}
            {OptionComponent ? (
              cloneElement(OptionComponent, { ...selected })
            ) : (
              <Box
                pl={0.5}
                sx={{
                  ...sizeProps,
                  textWrap: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {selected[optionLabel]}
              </Box>
            )}
          </Box>
          <SquareBackgroundIcon
            color="light-blue"
            icon={<DownIcon />}
            size={Size.SMALL}
          />
        </Stack>
      }
      position={Position.BOTTOM_LEFT}
      maxHeight="420px"
    >
      {options?.length > 0 ? (
        <ListOptions
          options={options}
          optionLabel={optionLabel}
          onClick={handleChange}
          OptionComponent={OptionComponent}
          sizeProps={sizeProps}
          selected={selected}
        />
      ) : (
        <Paragraph size={Size.SMALL}>No options are available</Paragraph>
      )}
    </Popover>
  );
};

export default Select;
