import { EntityType } from '@/shared/enums';
import { useScenarioContext, useViewModeContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';
import InsightsTitle from '../InsightsTitle/InsightsTitle';
import ActivitiesBarChart from './ActivitiesBarChart';

const ActivityInsights = () => {
  const { scenario, snapshotEntityMap } = useScenarioContext();
  const entityType = scenario?.entity?.is_manager
    ? EntityType.MANAGER
    : scenario?.entity?.__type;
  const { id, scope, scopeEntity } = useViewModeContext();
  const showSubgroupsSwitch =
    entityType &&
    entityType !== EntityType.ROLE &&
    scopeEntity !== EntityType.ROLE;

  return (
    <Grid container spacing={2} pb={4}>
      <Grid item xs={12}>
        <InsightsTitle
          entityMap={snapshotEntityMap}
          id={id}
          scope={scope}
          scopeEntity={scopeEntity}
        />
      </Grid>
      {scenario && (
        <Grid item xs={12}>
          <ActivitiesBarChart levelType={entityType} />
        </Grid>
      )}
    </Grid>
  );
};

export default ActivityInsights;
