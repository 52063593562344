import { ChartRowType, EntityPluralName } from '@/shared/enums';
import { percent } from '@pkg/utils/numbers';

const addEntityRows = ({
  chartRows,
  entity,
  excludeSeparators,
  id,
  metrics,
  sort,
}) => {
  const { props, type } = entity;
  const childEntity = entity?.childEntity;

  const percentage = percent(entity.metrics.hours, metrics.hours, 2);

  chartRows.push({
    data: {
      ...entity.metrics,
      percentage,
    },
    id,
    label: props?.name ?? props?.title ?? props.description,
    props,
    type,
    hasChildren: Boolean(childEntity),
  });

  if (childEntity) {
    [...entity[childEntity].entries()]
      .sort(([, a], [, b]) => {
        if (sort.type === 'ASC') {
          return a.metrics[sort.metric] - b.metrics[sort.metric];
        }

        return b.metrics[sort.metric] - a.metrics[sort.metric];
      })
      .forEach(([childId, childEntity]) => {
        addEntityRows({
          chartRows,
          entity: childEntity,
          excludeSeparators,
          id: `${id}:${childId}`,
          metrics,
          sort,
        });
      });
  }
};

/**
 * Takes an aggregated list of activities and converts them into rows of data.
 *
 * @param { Map } activities
 * @param { Object } metrics
 * @param { Object } filter
 *
 * @return { Array }
 */
export default function entityChart({
  entities,
  excludeSeparators,
  sort = { metric: 'hours', type: 'DESC' },
}) {
  const { order, metrics } = entities;

  if (!entities || !order.length) {
    return [];
  }

  // Set the title.
  const chartRows = excludeSeparators
    ? []
    : [
        {
          id: `${order[0]}:TITLE`,
          type: ChartRowType.TITLE,
          entity: order[0],
          label: EntityPluralName[order[0]],
        },
      ];

  [...entities[order[0]].entries()]
    .sort(([, a], [, b]) => {
      if (sort.type === 'ASC') {
        return a.metrics[sort.metric] - b.metrics[sort.metric];
      }

      return b.metrics[sort.metric] - a.metrics[sort.metric];
    })
    .forEach(([id, entity]) => {
      addEntityRows({
        chartRows,
        entity,
        excludeSeparators,
        id,
        metrics,
        sort,
      });
    });

  return chartRows;
}
