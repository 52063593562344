import { DesignEntity, Visibility } from '@/lib/enums';

const filterEntities = (map) => {
  const filtered = [...map.entries()].filter(([, entity]) => {
    const hasHours =
      entity.__type !== DesignEntity.ACTIVITY || entity.hours !== null;
    const isVisible = entity?.__visibility !== Visibility.NONE;

    return hasHours && isVisible;
  });

  return new Map(filtered);
};

export default function formatScenario({
  design,
  snapshot,
  snapshotEntityMap,
  entity,
  includeNested,
}) {
  const { collaborators, is_scenario, name, owner, scope, uuid } = design;
  const goal = design.goal ?? design.latest?.snapshot?.objective;
  const entityType = entity.type;
  const shouldIncludeNested =
    includeNested &&
    (entityType === DesignEntity.GROUP || entityType === DesignEntity.MANAGER);
  let selectedEntity;

  switch (entity.type) {
    case DesignEntity.ORGANISATION:
      const selectedDesign = snapshotEntityMap.get(entity.type);
      selectedEntity = {
        ...snapshot,
        updated_at: selectedDesign.created_at,
        __uuid: selectedDesign.uuid,
      };
      delete selectedEntity.entities;
      break;
    default:
      selectedEntity = snapshotEntityMap.get(entity.type).get(entity.uuid);
  }

  const scenario = {
    details: {
      collaborators,
      designId: uuid,
      goal,
      isScenario: is_scenario,
      name,
      owner,
      scope,
      hash: snapshot?.__hash,
    },
    organisation: snapshotEntityMap.get(DesignEntity.ORGANISATION),
    entity: selectedEntity,
    relationships: new Map(),
  };

  // short-circuit organisation levels
  if (entityType === DesignEntity.ORGANISATION) {
    const people = filterEntities(snapshotEntityMap.get(DesignEntity.PERSON));
    const groups = filterEntities(snapshotEntityMap.get(DesignEntity.GROUP));
    const roles = filterEntities(snapshotEntityMap.get(DesignEntity.ROLE));
    const activities = filterEntities(
      snapshotEntityMap.get(DesignEntity.ACTIVITY)
    );

    scenario.relationships.set(DesignEntity.PERSON, people);
    scenario.relationships.set(DesignEntity.GROUP, groups);
    scenario.relationships.set(DesignEntity.ROLE, roles);
    scenario.relationships.set(DesignEntity.MANAGER, roles); // @todo
    scenario.relationships.set(DesignEntity.ACTIVITY, activities);

    return scenario;
  }

  // setup data
  const entityId = entity.uuid;
  const groupMap = new Map();
  const roleMap = new Map();
  const activityMap = new Map();
  const personMap = new Map();

  // iterate activities to construct map
  snapshotEntityMap
    .get(DesignEntity.ACTIVITY)
    .values()
    .forEach((activity) => {
      // filtered activities
      if (
        activity.__visibility === Visibility.NONE ||
        activity.hours === null
      ) {
        return;
      }

      // If this is a manager role.
      const isUnallocated = activity.owner_type === DesignEntity.GROUP;

      const role = isUnallocated
        ? null
        : snapshotEntityMap.get(DesignEntity.ROLE).get(activity.owner_uuid);

      const person = role
        ? snapshotEntityMap.get(DesignEntity.PERSON).get(role?.user_uuid)
        : null;

      const group = snapshotEntityMap
        .get(DesignEntity.GROUP)
        .get(isUnallocated ? activity.owner_uuid : role?.group_uuid);

      if (activity.owner_uuid === entityId || role?.group_uuid === entityId) {
        activityMap.set(activity.uuid, activity);

        if (role && !roleMap.has(role.uuid)) {
          roleMap.set(role.uuid, role);
        }
        if (person && !personMap.has(person.uuid)) {
          personMap.set(person.uuid, person);
        }

        if (group && entityType === DesignEntity.GROUP) {
          if (!groupMap.has(group.uuid)) {
            groupMap.set(group.uuid, group);
          }
        }
      }

      // If this is an unallocated activity in a group entity, include it and
      // exit.
      if (isUnallocated) {
        if (group && !groupMap.has(group.uuid)) {
          groupMap.set(group.uuid, group);
        }

        return;
      }

      // If we're not including nested entities we should exit at this point.
      if (!shouldIncludeNested) {
        return;
      }

      // We're here because the activity doesn't belong to the current entity
      // but we want to include nested roles and this checks if the activity
      // owner is included in the hierarchy of the scenario entity.
      if (role?.__path.has(entityId)) {
        activityMap.set(activity.uuid, activity);

        if (role && !roleMap.has(role.uuid)) {
          roleMap.set(role.uuid, role);
        }
        if (person && !personMap.has(person.uuid)) {
          personMap.set(person.uuid, person);
        }
      }
    });

  scenario.relationships.set(DesignEntity.ROLE, roleMap);
  scenario.relationships.set(DesignEntity.ACTIVITY, activityMap);
  scenario.relationships.set(DesignEntity.PERSON, personMap);

  // only return surrounding group for role level
  if (!selectedEntity?.is_manager && entity.type === DesignEntity.ROLE) {
    const groupId = selectedEntity.group_uuid;
    const group = snapshotEntityMap.get(DesignEntity.GROUP).get(groupId);
    scenario.relationships.set(DesignEntity.GROUP, group ?? null);
    return scenario;
  }

  // include all connected groups for group level
  snapshotEntityMap
    .get(DesignEntity.GROUP)
    .values()
    .forEach((group) => {
      if (!selectedEntity?.__above?.[DesignEntity.GROUP]?.has(group.uuid)) {
        groupMap.set(group.uuid, group);
      }
    });

  scenario.relationships.set(DesignEntity.GROUP, groupMap);
  return scenario;
}
