/**
 * Updates the chart data rows by filtering hidden rows applying expanded
 * properties.
 *
 * @param { Object } data
 * @param { Map } expandMap
 *
 * @return { Object }
 */
export default function filterVisibleChartData(data, expandMap) {
  const filteredData = [];
  const updatedExpandMap = new Map(expandMap);

  data.forEach((row, index) => {
    let visible = false;
    let expanded = false;
    // Rows id's are colon separated to indicate their nesting level.
    const parentIdIndex = row.id.lastIndexOf(':');
    // We get the parent id of the current row to determine if it exists in
    // the map of expanded rows.
    const parentRow = row.id.slice(0, parentIdIndex);

    // If there is no parent, this is a top level row that should always be
    // shown.
    if (index === 0 || parentIdIndex < 0) {
      visible = true;
    }

    // If this row should be expanded, we want to reset the child count to zero.
    // The child count is used to determine how many child rows are expanded.
    // This is used for display purposes to render grouping in an efficient way.
    if (expandMap.has(row.id)) {
      visible = true;
      expanded = true;
      updatedExpandMap.set(row.id, 0);
    }

    // If this row's parent is expanded, it means this row should be shown.
    if (expandMap.has(parentRow)) {
      visible = true;
      const existing = updatedExpandMap.get(parentRow);
      // We update the child count for the expanded parent row to include this
      // row.
      updatedExpandMap.set(parentRow, existing + 1);

      // We also look for the parent above this parent row to update it's child
      // count of rows.
      const previousParentIdIndex = parentRow.lastIndexOf(':');
      if (previousParentIdIndex > 0) {
        const previousParentId = row.id.slice(0, previousParentIdIndex);
        const previousParent = updatedExpandMap.get(previousParentId);
        updatedExpandMap.set(previousParentId, previousParent + 1);
      }
    }

    if (visible) {
      filteredData.push({
        ...row,
        expanded,
        visible,
      });
    }
  });

  return {
    data: filteredData,
    expandMap: updatedExpandMap,
  };
}
