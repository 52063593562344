import { DesignEntity } from '@/lib/enums';

/**
 * @param {Object}
 * @return {Object}
 */
export default function aggregateActivity({
  activity,
  colors,
  entity,
  existing,
  group,
  role,
  storeEntity = true,
}) {
  const entityType = entity.__type;
  const isActive = Boolean(!activity.disabled_at);
  const rolePercentage =
    role && role?.__metrics ? activity.hours / role.__metrics?.hours : 0;

  const budget = role && rolePercentage > 0 ? rolePercentage * role.budget : 0;
  const fte = role && rolePercentage > 0 ? rolePercentage * role.fte : 0;

  const roleProps = role
    ? {
        uuid: role.uuid,
        title: role.title,
        hours: activity.hours,
        budget,
        fte,
        group: group
          ? {
              uuid: group.uuid,
              name: group.name,
            }
          : null,
        person: role.__person ? { ...role.__person } : null,
      }
    : null;

  if (existing) {
    return {
      ...existing,
      __aggregateHours: isActive
        ? existing.__aggregateHours + activity.hours
        : existing.__aggregateHours,
      __aggregateBudget: isActive
        ? existing.__aggregateBudget + budget
        : existing.__aggregateBudget,
      __aggregateFTE: isActive
        ? existing.__aggregateFTE + fte
        : existing.__aggregateFTE,
      __roles: role
        ? existing.__roles.set(role.uuid, roleProps)
        : existing.__roles,
    };
  }

  // Allows us to avoid storing the entity properties if we wish.
  const entityProperties = storeEntity
    ? { ...entity }
    : {
        uuid: entity.uuid,
        disabled_at: activity.disabled_at,
        hours: activity.hours,
        library_uuid: activity.library_uuid,
        order: activity.order,
        tags: activity.tags,
        updated_at: activity.updated_at,
      };

  if (colors) {
    entityProperties.__color = colors;
  }

  if (entityType === DesignEntity.GROUP || entityType === DesignEntity.ROLE) {
    entityProperties.__total_metrics = entity.__total_metrics;
  }

  return {
    ...entityProperties,
    __description:
      entityType === DesignEntity.ACTIVITY
        ? activity.__description
        : entity.name ?? entity.title,
    __aggregateHours: entityProperties.hours,
    __aggregateBudget: budget,
    __aggregateFTE: fte,
    __roles: role ? new Map([[role.uuid, roleProps]]) : new Map(),
  };
}
