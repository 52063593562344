import { Size } from '@/atoms/enums';
import { MetricProperty } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import Stack from '@mui/material/Stack';
import { format } from '@pkg/utils/numbers';
import { EntityMetric } from '@/lib/enums';
import { RowColors } from '../utils';

const HorizontalBars = ({
  barHeight,
  row,
  metric,
  xChartPosition,
  xPosition,
  yPosition,
}) => {
  const width = xPosition - 50 > 4 ? xPosition - 50 : 4;
  const value =
    metric === EntityMetric.PERCENTAGE && row.data[metric] > 0
      ? row.data[metric] / 100
      : row.data[metric];
  const formatOptions = MetricProperty[metric]?.formatOptions;
  const displayValue = formatOptions
    ? format(value, {
        ...formatOptions,
        notation: 'compact',
      })
    : row.data[metric];

  const colors = RowColors[row.type].bar;

  return (
    <g className="horizontal-bar">
      <rect
        width={width}
        height={barHeight - 16}
        rx={2}
        ry={2}
        x={xChartPosition}
        y={yPosition + 6}
        fill={colors.backgroundColor}
        strokeWidth={1}
        stroke={colors.borderColor}
      />
      <foreignObject
        x={xChartPosition + width + 8}
        y={yPosition}
        width={50}
        height={barHeight}
      >
        <Stack direction="row" alignItems="center" height={barHeight}>
          <Paragraph
            size={Size.SMALL}
            overrideStyles={{
              mb: 0,
              fontWeight: 600,
            }}
          >
            {displayValue}
          </Paragraph>
        </Stack>
      </foreignObject>
    </g>
  );
};

export default HorizontalBars;
