import {
  FilterEntity,
  FilterProperty as Property,
} from '@/organisms/filters/enums';
import PropertyMap from './filterPropertyMap';

export const ActivityProperties = new Map([
  [Property.DESCRIPTION, PropertyMap.get(Property.DESCRIPTION)],
  [Property.TAG, PropertyMap.get(Property.TAG)],
  [Property.PROPERTY, PropertyMap.get(Property.PROPERTY)],
  [Property.HOURS, PropertyMap.get(Property.HOURS)],
  [Property.CREATED_AT, PropertyMap.get(Property.CREATED_AT)],
  [Property.UPDATED_AT, PropertyMap.get(Property.UPDATED_AT)],
]);

export const GroupProperties = new Map([
  [Property.NAME, PropertyMap.get(Property.NAME)],
  [Property.TAG, PropertyMap.get(Property.TAG)],
  [Property.LEAD, PropertyMap.get(Property.LEAD)],
  [Property.PROPERTY, PropertyMap.get(Property.PROPERTY)],
  [Property.HOURS, PropertyMap.get(Property.HOURS)],
  [Property.FTE, PropertyMap.get(Property.FTE)],
  [Property.BUDGET, PropertyMap.get(Property.BUDGET)],
  [Property.CREATED_AT, PropertyMap.get(Property.CREATED_AT)],
  [Property.UPDATED_AT, PropertyMap.get(Property.UPDATED_AT)],
]);

export const PeopleProperties = new Map([
  [Property.SKILL, PropertyMap.get(Property.SKILL)],
]);

export const PropertyProperties = new Map([
  [Property.ROLE, PropertyMap.get(Property.ROLE)],
  [Property.GROUP, PropertyMap.get(Property.GROUP)],
  [Property.ACTIVITY, PropertyMap.get(Property.ACTIVITY)],
]);

export const RoleProperties = new Map([
  [Property.TITLE, PropertyMap.get(Property.TITLE)],
  [Property.ASSIGNEE, PropertyMap.get(Property.ASSIGNEE)],
  [Property.TAG, PropertyMap.get(Property.TAG)],
  [Property.SKILL, PropertyMap.get(Property.SKILL)],
  [Property.PROPERTY, PropertyMap.get(Property.PROPERTY)],
  [Property.HOURS, PropertyMap.get(Property.HOURS)],
  [Property.IS_MANAGER, PropertyMap.get(Property.IS_MANAGER)],
  [Property.SPAN, PropertyMap.get(Property.SPAN)],
  [Property.LAYER, PropertyMap.get(Property.LAYER)],
  [Property.LAYERS, PropertyMap.get(Property.LAYERS)],
  [Property.FTE, PropertyMap.get(Property.FTE)],
  [Property.BUDGET, PropertyMap.get(Property.BUDGET)],
  [Property.CREATED_AT, PropertyMap.get(Property.CREATED_AT)],
  [Property.UPDATED_AT, PropertyMap.get(Property.UPDATED_AT)],
]);

export const SkillProperties = new Map([
  [Property.ROLE, PropertyMap.get(Property.ROLE)],
  [Property.PEOPLE, PropertyMap.get(Property.PEOPLE)],
]);

export const TagProperties = new Map([
  [Property.ROLE, PropertyMap.get(Property.ROLE)],
  [Property.GROUP, PropertyMap.get(Property.GROUP)],
  [Property.ACTIVITY, PropertyMap.get(Property.ACTIVITY)],
]);

const EntityPropertyMap = new Map([
  [FilterEntity.ACTIVITY, ActivityProperties],
  [FilterEntity.GROUP, GroupProperties],
  [FilterEntity.PROPERTY, PropertyProperties],
  [FilterEntity.ROLE, RoleProperties],
  [FilterEntity.SKILL, SkillProperties],
  [FilterEntity.TAG, TagProperties],
  [FilterEntity.PEOPLE, PeopleProperties],
]);

export default EntityPropertyMap;
