import { MetricProperty } from '@/atoms/enums';
import { EntityMetric } from '@/lib/enums';

const MetricOptions = [
  {
    id: EntityMetric.PERCENTAGE,
    label: MetricProperty[EntityMetric.PERCENTAGE].label.singular,
  },
  {
    id: EntityMetric.HOURS,
    label: MetricProperty[EntityMetric.HOURS].label.plural,
  },
  {
    id: EntityMetric.BUDGET,
    label: MetricProperty[EntityMetric.BUDGET].label.singular,
  },
  {
    id: EntityMetric.FTE,
    label: MetricProperty[EntityMetric.FTE].label.singular,
  },
];

export default MetricOptions;
