import { useMemo } from 'react';
import { LinkButton } from '@/atoms/buttons';
import { EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import { OutlineCard } from '@/organisms/cards';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Colors } from '@pkg/utils';
import { DesignEntity } from '@/lib/enums';

const RoleCard = ({ role, showLink }) => {
  const entityType = role.is_manager ? DesignEntity.MANAGER : DesignEntity.ROLE;

  const color = Colors.entity(entityType);

  const path = useMemo(() => {
    let designId;

    role.__scenarios.forEach((design, key) => {
      if (!designId || !design.__isScenario) {
        designId = design.__isScenario ? key : 'main';
      }
    });

    if (!designId) {
      return;
    }

    if (role.is_manager) {
      return `/d/${designId}/latest/m/${role.uuid}`;
    }

    return `/role/${role.uuid}/${designId}/latest`;
  }, [role?.__scenarios]);

  return (
    <OutlineCard padding={0} margin={0}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pr={2}
        sx={{
          overflow: 'hidden',
          borderRadius: '3px',
        }}
      >
        <Box
          p={1}
          pl={3}
          sx={{
            boxShadow: `inset 8px 0 0 ${color.main}`,
          }}
        >
          <EntityChip size={Size.SMALL} type={entityType} />
          <Heading
            variant="h2"
            overrideStyles={{
              fontWeight: 400,
              fontSize: '1.5rem',
              lineHeight: '1.75em',
            }}
          >
            {role.title}
          </Heading>
          {role.__group?.name && (
            <Stack direction="row" spacing={1} alignItems="center" mt={1}>
              <>
                <EntityChip size={Size.SMALL} type={DesignEntity.GROUP} />
                <Paragraph size={Size.SMALL}>{role.__group.name}</Paragraph>
              </>
            </Stack>
          )}
        </Box>
        {showLink && path && (
          <LinkButton
            boldText
            color="secondary"
            label="View"
            size={Size.MEDIUM}
            path={path}
          />
        )}
      </Stack>
    </OutlineCard>
  );
};

export default RoleCard;
