import { useState } from 'react';
import { Colors } from '@/atoms/buttons';
import { ToggleButtonSize } from '@/atoms/buttons/utils';
import { Size } from '@/atoms/enums';
import MuiToggleButton from '@mui/material/ToggleButton';

const ToggleButton = ({
  color = 'primary',
  colorProps,
  disabled,
  initialState = false,
  offIcon,
  onIcon,
  onChange,
  size = Size.MEDIUM,
  track,
}) => {
  const [selected, setSelected] = useState(initialState);

  const colors = colorProps ?? Colors.toggle[color];
  const sizeProps = { ...ToggleButtonSize[size] };

  const handleChange = () => {
    const newValue = !selected;

    setSelected(newValue);
    onChange?.(newValue);
  };

  return (
    <MuiToggleButton
      value="check"
      selected={selected}
      onChange={handleChange}
      color="primary"
      sx={{
        border: 'none',
        ...sizeProps.button,
        ...colors.normal,
        '&:hover': {
          ...colors.hover,
        },
        '&.Mui-selected': {
          ...colors.selected,
          '&:hover': {
            ...colors.selectedHover,
          },
        },
        '& .MuiSvgIcon-root': {
          ...sizeProps.svg,
        },
      }}
    >
      {selected ? onIcon : offIcon}
    </MuiToggleButton>
  );
};

export default ToggleButton;
