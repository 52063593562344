import { Collections, Sort } from '@pkg/utils';
import shortuuid from '@pkg/uuid';
import { ActivityOwner } from '@/lib/enums';

/**
 * @param {Object} snapshot
 * @param {Object[]} activities
 * @returns {Object}
 */
export default function add(snapshot, activities) {
  const now = Date.now();
  const entities = snapshot.entities;

  const touchedGroups = new Set();
  const touchedRoles = new Set();

  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
      },
      roles: {
        update: [],
      },
      activities: {
        create: [],
        update: [],
      },
    },
  };

  activities.forEach((input) => {
    const {
      new_uuid,
      library_uuid,
      owner_uuid,
      owner_type,
      source_type,
      source_uuid,
      hours,
      order,
      properties,
      tags,
    } = input;

    const siblings = Collections.whereOwner(entities.activities, owner_uuid);
    siblings.sort(Sort.order());

    if (owner_type === ActivityOwner.ROLE) {
      touchedRoles.add(owner_uuid);
    } else if (owner_type === ActivityOwner.GROUP) {
      touchedGroups.add(owner_uuid);
    }

    const added = {
      uuid: new_uuid ?? shortuuid.generate(),
      library_uuid,
      owner_uuid,
      owner_type,
      source_type,
      source_uuid,
      hours: hours ?? 1,
      order: order ?? 0,
      properties: properties ?? [],
      tags: tags ?? [],
      created_at: now,
      updated_at: now,
    };

    mutation.entities.activities.create.push(added);

    siblings.splice(order, 0, added);
    siblings.forEach((activity, order) => {
      if (activity.uuid === added.uuid || activity.order === order) {
        return;
      }

      mutation.entities.activities.update.push({
        uuid: activity.uuid,
        updated_at: now,
        order,
      });
    });
  });

  touchedRoles.forEach((id) => {
    mutation.entities.roles.update.push({ uuid: id, updated_at: now });
  });

  touchedGroups.forEach((id) => {
    mutation.entities.groups.update.push({ uuid: id, updated_at: now });
  });

  return mutation;
}
