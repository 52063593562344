import { Collections, Sort } from '@pkg/utils';
import shortuuid from '@pkg/uuid';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {Object} input.id
 * @param {Object} [input.newId]
 * @returns {Object}
 */
export default function duplicate(snapshot, { id, newId }) {
  const entities = structuredClone(snapshot.entities);
  const now = Date.now();
  const mutation = {
    created_at: now,
    entities: {
      roles: {
        update: [],
        create: [],
      },
      activities: {
        create: [],
      },
    },
  };

  // prepare data
  const role = Collections.findById(entities.roles, id);
  const activities = Collections.where(entities.activities, 'owner_uuid', id);
  const roles = Collections.where(
    entities.roles,
    'parent_uuid',
    role.parent_uuid
  );
  const index = roles.findIndex((role) => role.uuid === id);

  activities.sort(Sort.order());
  roles.sort(Sort.order());

  // setup duplicate
  const duplicate = {
    uuid: newId ?? shortuuid.generate(),
    group_uuid: role.group_uuid,
    parent_uuid: role.parent_uuid,
    user_uuid: null,
    title: role.title,
    fte: role.fte,
    order: index + 1,
    properties: role.properties,
    skills: role.skills,
    tags: role.tags,
    created_at: now,
    updated_at: now,
  };

  if (Number.isInteger(role.budget)) {
    duplicate.budget = role.budget;
  }

  // assemble duplicates
  roles.splice(index + 1, 0, duplicate);
  mutation.entities.roles.create.push(duplicate);

  activities.forEach((activity, index) => {
    mutation.entities.activities.create.push({
      uuid: shortuuid.generate(),
      library_uuid: activity.library_uuid,
      owner_uuid: duplicate.uuid,
      owner_type: activity.owner_type,
      order: index,
      hours: activity.hours,
      properties: activity.properties,
      tags: activity.tags,
      created_at: now,
      updated_at: now,
    });
  });

  // udpate role orders if relevant
  roles.forEach((role, order) => {
    if (role.order !== order) {
      mutation.entities.roles.update.push({
        uuid: role.uuid,
        order,
        updated_at: now,
      });
    }
  });

  // cleanup mutation object
  Object.keys(mutation.entities).forEach((type) => {
    Object.keys(mutation.entities[type]).forEach((action) => {
      if (!mutation.entities[type][action].length) {
        delete mutation.entities[type][action];
      }
    });
  });

  return mutation;
}
