import { Base as BaseColors } from '@/atoms/colors';
import { Colors as InputColors } from '@/atoms/inputs';
import Box from '@mui/material/Box';
import { styled } from '@mui/styles';
import excludeProps from '@/lib/theme/excludeProps';

const StyledBox = styled(Box, {
  shouldForwardProp: excludeProps(['color', 'isFocus']),
})(({ color }) => {
  return {
    ...InputColors.Select.option[color].normal,
    color: BaseColors.font.primary,
    fontSize: '0.875rem',
    padding: '4px',
    margin: 0,
    boxShadow: `inset 0 -1px 0 0 ${InputColors.Select.option.primary.normal.borderColor}77`,
    backgroundColor: 'transparent',
    '&:focus': {
      outline: 'none',
    },
    '&:last-child': {
      boxShadow: 'none',
    },
  };
});

const SelectItem = ({
  children,
  color = 'secondary',
  isFocus,
  onClick,
  onFocus,
  value,
}) => {
  const handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      onClick?.(event, value);
    }
  };

  return (
    <StyledBox
      color={color}
      onKeyDown={handleKeyDown}
      isFocus={isFocus}
      onClick={onClick}
      onFocus={onFocus}
      tabIndex={0}
      value={value}
    >
      <Box
        pl={1}
        pr={2}
        py={0.5}
        sx={{
          backgroundColor: isFocus
            ? InputColors.Select.option[color].hover.backgroundColor
            : null,
          '&:hover, &:focus': {
            ...InputColors.Select.option[color].hover,
            cursor: 'pointer',
          },
          '&:focus': {
            outline: 'none',
            boxShadow: `inset 0 0 0 1px ${InputColors.Select.option[color].focus.borderColor}`,
          },
        }}
      >
        {children}
      </Box>
    </StyledBox>
  );
};

export default SelectItem;
