import { Snapshots } from '@pkg/entities';
import { Collections } from '@pkg/utils';
import shortuuid from '@pkg/uuid';
import config from '@/lib/config';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} input.group_uuid
 * @param {String} input.parent_uuid
 * @param {String} input.title
 * @param {Array} input.activities
 * @returns {Object}
 */
export default function add(
  snapshot,
  { uuid, parent_uuid, group_uuid, title, activities, roleCount = 1 }
) {
  const now = Date.now();
  const keyed = Collections.keyById(snapshot.entities.roles);

  const role = {
    uuid: uuid ?? shortuuid.generate(),
    parent_uuid: Object.hasOwn(keyed, parent_uuid) ? parent_uuid : null,
    group_uuid,
    user_uuid: null,
    title: title || config.DEFAULT_ROLE_TITLE,
    order: -1,
    fte: config.DEFAULT_FTE,
    properties: [],
    skills: [],
    tags: [],
    created_at: now,
    updated_at: now,
  };

  let newRoles = Array.from({ length: roleCount }, () => role);
  const newActivities = [];

  if (roleCount > 1) {
    newRoles = newRoles.map((role) => {
      return {
        ...role,
        uuid: shortuuid.generate(),
      };
    });
  }

  newRoles.forEach((role) => {
    const newSnapshot = {
      entities: {
        groups: [],
        roles: [role],
        activities: [],
      },
    };

    const activityMutation = Snapshots.reducers.activities.add(
      newSnapshot,
      activities || []
    );

    const roleActivities = activityMutation.entities.activities.create.map(
      (activity) => {
        return {
          ...activity,
          owner_uuid: role.uuid,
          uuid: shortuuid.generate(),
        };
      }
    );

    newActivities.push(...roleActivities);
  });

  const mutation = {
    created_at: now,
    entities: {
      roles: {
        create: newRoles,
      },
      activities: { create: newActivities },
    },
  };

  return mutation;
}
