import { Shadow } from '@/atoms/colors';
import * as Colors from '@/atoms/colors';
import { brand } from '@/lib/theme/tokens/palettes';

const ModalColors = Object.freeze({
  'primary': {
    boxShadow: `${Shadow.high}, 0 0 0 1px ${Colors.Card.outline.borderColor}`,
  },
  'secondary': {
    backgroundColor: brand.lightBlue.tints[3],
    boxShadow: `${Shadow.low}, 0 0 0 1px ${brand.lightBlue.tints[5]}`,
  },
});

export default ModalColors;
