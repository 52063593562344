import { DesignEntity, DesignScope } from '@/lib/enums';

export default function getScenarioEntity(scenario) {
  const entityType = DesignEntity.fromScope(scenario.scope);
  const plural = DesignEntity.toPlural(entityType);
  const entities = scenario.latest.snapshot.entities[plural];

  return scenario.scope === DesignScope.ORGANISATION
    ? scenario.latest
    : scenario.scope === DesignScope.MANAGER
      ? entities.find(({ parent_uuid }) => !parent_uuid)
      : entities[0];
}
