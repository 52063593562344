import { useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Base as BaseColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { ActivityDimensions } from '@/molecules/chartElements';
import { OutlineCard } from '@/organisms/cards';
import { D3Chart, HorizontalBarChart } from '@/organisms/charts';
import { useAggregateChartData } from '@/organisms/insights';
import { EntityType } from '@/shared/enums';
import { ScopeType } from '@/shared/enums';
import { useInsightsContext } from '@/shared/providers';
import { useViewModeContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const BAR_HEIGHT = 34;

const ActivitySummary = ({ entityType, entityId, limit = 6 }) => {
  const { selectScope } = useViewModeContext();
  const { filter } = useInsightsContext();
  const [dimension, setDimension] = useState(
    ActivityDimensions[EntityType.ACTIVITY]
  );
  const sort = {
    metric: 'hours',
    type: 'DESC',
  };
  const { chartData, metricTotals } = useAggregateChartData({
    dimension,
    filter,
    sort,
  });

  const hasActivities = metricTotals?.activities > 0;

  const handleClick = () => {
    selectScope(ScopeType.ACTIVITIES);
  };

  return (
    chartData?.length > 0 && (
      <OutlineCard
        title="Where time is spent"
        headerAction={
          hasActivities && (
            <Button
              label="View all"
              color="secondary"
              onClick={handleClick}
              size={Size.X_SMALL}
            />
          )
        }
        margin={0}
        padding={0}
      >
        <Box textAlign="center">
          {hasActivities ? (
            <D3Chart
              height={216}
              hideBorder
              id="activity-insights"
              noScroll
              restrictHeight
            >
              <HorizontalBarChart
                initialData={chartData.slice(0, limit)}
                labelWidthPercentage={0.5}
                metric="percentage"
                metricTotals={metricTotals}
                sort={sort}
              />
            </D3Chart>
          ) : (
            <Stack
              alignItems="center"
              justifyContent="center"
              height={216}
              sx={{
                boxShadow: `0 0 0 1px ${BaseColors.border.primary}`,
              }}
            >
              <Paragraph size={Size.SMALL}>No activities were found.</Paragraph>
            </Stack>
          )}
        </Box>
      </OutlineCard>
    )
  );
};

export default ActivitySummary;
