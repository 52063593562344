import { EntityType } from '@/shared/enums';
import { DesignEntity, Visibility } from '@/lib/enums';
import { Position } from '@/lib/enums';
import TagCategory from '@/components/DashboardContainer/enums/TagCategory';

const defaultEntitySets = () => ({
  roles: new Set(),
  groups: new Set(),
  activities: new Set(),
});

const excludeActivity = ({ activity, filter, group, role }) => {
  const ids = filter?.ids;
  if (!ids || ids?.has('ALL')) {
    return;
  }

  switch (filter.entity) {
    case EntityType.GROUP:
      if (ids.has(group?.uuid)) {
        return;
      }

      if (filter.options?.hideNestedEntities) {
        return !ids.has(group?.__parent?.uuid);
      }

      const groups = group?.__above?.[DesignEntity.GROUP];

      if (!groups) {
        return true;
      }

      return groups && ![...groups].some((id) => [...filter.ids].includes(id));

    case EntityType.ROLE:
      return !ids.has(role?.uuid);

    case EntityType.MANAGER:
      if (ids.has(role?.uuid)) {
        return;
      }

      if (filter.options?.hideNestedEntities) {
        return !ids.has(role.__manager);
      }

      const managers = role?.__above?.[DesignEntity.MANAGER];
      return (
        managers && ![...managers].some((id) => [...filter.ids].includes(id))
      );

    case EntityType.ACTIVITY:
      return !ids.has(activity?.uuid);

    case EntityType.TAG:
      let include = false;
      activity.tags.forEach((id) => {
        if (ids.has(id)) {
          include = true;
        }
      });
      return !include;
    default:
  }
};

const getProps = ({ entityType, activity, role, person, group, tag }) => {
  if (entityType === DesignEntity.ACTIVITY) {
    return {
      id: activity.library_uuid,
      uuid: activity.uuid,
      props: {
        description: activity.__description,
        library_uuid: activity.library_uuid,
      },
    };
  }
  if (entityType === DesignEntity.ROLE) {
    return {
      id: role.title,
      uuid: role.uuid,
      props: {
        title: role.title,
        isUnallocated: role.isUnallocated ?? false,
      },
    };
  }
  if (entityType === DesignEntity.GROUP) {
    return {
      id: group?.name ?? 'UNALLOCATED',
      uuid: group?.uuid ?? 'UNALLOCATED',
      props: {
        name: group?.name ?? 'No team',
      },
    };
  }
  if (entityType === DesignEntity.PERSON) {
    return {
      id: person.uuid,
      props: {
        name: person.name,
        uuid: person.uuid,
        avatar: person.avatar,
        isUnassigned: person.isUnassigned ?? false,
        role_uuid: role.uuid,
      },
    };
  }
  if (entityType === 'TAG') {
    return {
      id: tag.uuid,
      props: {
        uuid: tag.uuid,
        description: tag.description,
        name: tag.name,
        color: tag.color,
      },
    };
  }
};

const aggregateEntity = ({
  activityMetrics,
  props,
  id,
  map,
  nextEntity,
  type,
  uuid,
}) => {
  const existing = map.get(id);

  if (existing) {
    const metrics = {
      count: existing.metrics.count + 1,
      hours: existing.metrics.hours + activityMetrics.hours,
      budget: existing.metrics.budget + activityMetrics.budget,
      fte: existing.metrics.fte + activityMetrics.fte,
    };

    const entity = {
      ...existing,
      metrics,
    };

    if (uuid) {
      entity.entitySet = existing.entitySet.add(uuid);
    }

    map.set(id, entity);
  } else {
    const entityProps = {
      props,
      type,
      metrics: {
        count: 1,
        hours: activityMetrics.hours,
        budget: activityMetrics.budget,
        fte: activityMetrics.fte,
      },
    };

    if (uuid) {
      entityProps.entitySet = new Set([uuid]);
    }

    if (nextEntity) {
      entityProps.childEntity = nextEntity;
      entityProps[nextEntity] = new Map();
    }

    map.set(id, entityProps);
  }

  if (nextEntity) {
    map = map.get(id)?.[nextEntity];
  }

  return map;
};

const createMatrixMap = ({ matrix, metrics, order }) => {
  const first = matrix.items[0];
  const second = matrix.items[1];

  const matrixMap = new Map([
    [
      'NONE',
      {
        id: 'NONE',
        uuid: 'NONE',
        [order[0]]: new Map(),
        entitySets: defaultEntitySets(),
        metrics: {
          ...metrics,
          activities: 0,
          roles: 0,
          groups: 0,
        },
        props: {
          position: second ? Position.BOTTOM_LEFT : Position.RIGHT,
          id: 'NONE',
        },
        type: EntityType.TAG,
      },
    ],
  ]);

  if (first) {
    matrixMap.set(first.id, {
      id: first.id,
      uuid: first.id,
      [order[0]]: new Map(),
      entitySets: defaultEntitySets(),
      metrics: { ...metrics },
      props: {
        ...first,
        position: second ? Position.TOP_LEFT : Position.LEFT,
      },
      type: EntityType.TAG,
    });
  }

  if (second) {
    matrixMap.set(second.id, {
      id: second.id,
      uuid: second.id,
      [order[0]]: new Map(),
      entitySets: defaultEntitySets(),
      metrics: { ...metrics },
      props: {
        ...second,
        position: Position.BOTTOM_RIGHT,
      },
      type: EntityType.TAG,
    });
    matrixMap.set('ALL', {
      id: 'ALL',
      uuid: 'ALL',
      [order[0]]: new Map(),
      entitySets: defaultEntitySets(),
      metrics: { ...metrics },
      props: {
        position: Position.TOP_RIGHT,
      },
      type: EntityType.TAG,
    });
  }

  return {
    order,
    metrics: { ...metrics },
    MATRIX: matrixMap,
    entitySet: new Set(matrix.items.map(({ id }) => id)),
  };
};

const getMatrixId = (matrixMap, activityItem) => {
  let id = 'NONE';
  matrixMap.entitySet.forEach((tagId) => {
    if (activityItem.__tags_set.has(tagId)) {
      id = id === 'NONE' ? tagId : 'ALL';
    }
  });
  return id;
};

const createEntityMap = ({ matrix, metrics, order }) => {
  if (matrix) {
    return createMatrixMap({
      matrix,
      metrics: { ...metrics },
      order,
    });
  }

  return {
    order,
    [order[0]]: new Map(),
    metrics: { ...metrics },
  };
};

export default function mapActivityMatrix({
  activities,
  filter,
  includeUnallocated,
  matrix,
  order,
  snapshotEntityMap,
  tagMap,
}) {
  const tagFilter = filter?.entity === EntityType.TAG;
  const metrics = {
    count: 0,
    hours: 0,
    budget: 0,
    fte: 0,
    roles: 0,
    activities: 0,
    groups: 0,
    people: 0,
  };

  const entityMap = createEntityMap({
    matrix,
    metrics: { ...metrics },
    order,
  });

  const activitySet = new Set();
  const activityLibrarySet = new Set();
  const roleSet = new Set();
  const groupSet = new Set();
  const peopleSet = new Set();

  const matrixEntitySets = new Map();

  activities.forEach((activityItem) => {
    const isActive = Boolean(!activityItem.disabled_at);

    if (activityItem.__visibility === Visibility.NONE || !isActive) {
      return;
    }

    const role =
      activityItem.owner_type === DesignEntity.ROLE
        ? snapshotEntityMap.get(DesignEntity.ROLE).get(activityItem.owner_uuid)
        : {
            title: 'Unallocated',
            uuid: activityItem.owner_uuid,
            isUnallocated: true,
          };

    const group =
      role && !role.isUnallocated
        ? snapshotEntityMap.get(DesignEntity.GROUP).get(role.group_uuid)
        : snapshotEntityMap
            .get(DesignEntity.GROUP)
            .get(activityItem.owner_uuid);

    const person = role.__person
      ? {
          name: `${role.__person.first_name} ${role.__person.last_name}`,
          uuid: role.__person.uuid,
          avatar: role.__person.avatar,
        }
      : {
          name: 'Unassigned',
          uuid: 'unassigned',
          isUnassigned: true,
        };

    if (filter) {
      const shouldFilter = excludeActivity({
        activity: activityItem,
        filter,
        group,
        role,
      });

      if (shouldFilter) {
        return;
      }
    }

    activitySet.add(activityItem.uuid);
    activityLibrarySet.add(activityItem.library_uuid);

    if (group) {
      groupSet.add(group.uuid);
    }

    if (!role.isUnassigned) {
      roleSet.add(role.uuid);
    }
    if (!person.isUnassigned) {
      peopleSet.add(person.uuid);
    }

    const tags =
      activityItem.tags?.length > 0
        ? activityItem.tags
        : [TagCategory.NOT_TAGGED];

    const roleMetrics = role?.__metrics?.self?.total;

    const activityPercentage = roleMetrics
      ? activityItem.hours / roleMetrics.hours
      : 0;
    const budget =
      activityPercentage > 0 ? activityPercentage * roleMetrics.budget : 0;
    const fte =
      activityPercentage > 0 ? activityPercentage * roleMetrics.fte : 0;

    const activityMetrics = {
      hours: activityItem.hours,
      budget,
      fte,
    };

    if (!includeUnallocated && role.isUnallocated) {
      return;
    }

    if (!role.isUnallocated) {
      entityMap.metrics.budget += activityMetrics.budget;
      entityMap.metrics.count += 1;
      entityMap.metrics.fte += activityMetrics.fte;
      entityMap.metrics.hours += activityMetrics.hours;
    }

    let map;

    if (matrix) {
      const matrixId = getMatrixId(entityMap, activityItem);

      const matrixItem = entityMap['MATRIX'].get(matrixId);

      const addGroup =
        group?.uuid && !matrixItem.entitySets.groups.has(group?.uuid);
      const addRole =
        !role.isUnassigned && !matrixItem.entitySets.roles.has(role?.uuid);

      matrixItem.metrics.activities += 1;
      matrixItem.metrics.budget += activityMetrics.budget;
      matrixItem.metrics.count += 1;
      matrixItem.metrics.fte += activityMetrics.fte;
      matrixItem.metrics.hours += activityMetrics.hours;

      matrixItem.entitySets.activities.add(activityItem?.uuid);
      if (addRole) {
        matrixItem.metrics.roles += 1;
        matrixItem.entitySets.roles.add(role?.uuid);
      }
      if (addGroup) {
        matrixItem.metrics.groups += 1;
        matrixItem.entitySets.groups.add(group?.uuid);
      }

      map = matrixItem[order[0]];
    }

    if (!matrix) {
      map = entityMap[order[0]];
    }

    for (let i = 0; i < order.length; i++) {
      const entityType = order[i];

      const nextEntity = order[i + 1];

      if (entityType === 'TAG') {
        const remainingOrder = order.slice(i);

        tags.forEach((tagId) => {
          if (tagFilter && !filter.ids.has('ALL') && !filter.ids.has(tagId)) {
            return;
          }

          const tag =
            tagId === TagCategory.NOT_TAGGED
              ? {
                  uuid: tagId,
                  name: 'Not tagged',
                  color: 'GREY_LIGHT',
                }
              : tagMap.get(tagId);
          let tempMap = map;

          // If the tag doesn't exist in the library we want to exit here as a
          // safety precaution.
          if (!tag) {
            return;
          }

          // Add the remaining order for each tag.
          remainingOrder.forEach((entityType, index) => {
            const nextEntity = remainingOrder[index + 1];

            const props = getProps({
              activity: activityItem,
              entityType,
              group,
              role,
              person,
              tag,
            });

            tempMap = aggregateEntity({
              ...props,
              activityMetrics,
              map: tempMap,
              nextEntity,
              type: entityType,
            });
          });
        });

        return;
      }

      const props = getProps({
        activity: activityItem,
        entityType,
        group,
        role,
        person,
      });

      if (props) {
        map = aggregateEntity({
          ...props,
          activityMetrics,
          map,
          nextEntity,
          type: entityType,
        });
      }
    }
  });

  entityMap.metrics.activities = activitySet.size;
  entityMap.metrics.libraryActivities = activityLibrarySet.size;
  entityMap.metrics.roles = roleSet.size;
  entityMap.metrics.groups = groupSet.size;
  entityMap.metrics.people = peopleSet.size;

  return entityMap;
}
