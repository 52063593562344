import { useRef, useState } from 'react';
import { EntitiesTable, MatrixBox } from '@/organisms/charts';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Position } from '@/lib/enums';

const binaryPositions = [Position.LEFT, Position.RIGHT];

const BinaryMatrix = ({
  entityType,
  chartData,
  height = 400,
  metricMax,
  metricTotals,
  metric,
  showEntitiesTable = true,
  tags,
}) => {
  const ref = useRef();
  const [focusItem, setFocusItem] = useState();
  const [selected, setSelected] = useState(Position.RIGHT);
  const [titleHeight, setTitleHeight] = useState(0);

  const handleItemFocus = (item) => {
    setFocusItem(item.id);
  };

  const handleSelection = (position) => {
    setSelected(position);
  };

  const handleTitleHeight = (height) => {
    if (height > titleHeight) {
      setTitleHeight(height);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        <Box ref={ref}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
            alignItems="flex-end"
            ref={ref}
          >
            {binaryPositions.map((position) => (
              <div
                key={position}
                onClick={() => handleSelection(position)}
                style={{ width: '50%' }}
              >
                <MatrixBox
                  entityType={entityType}
                  height={height}
                  isSelected={selected === position}
                  metricMax={metricMax}
                  metricTotals={metricTotals}
                  metric={metric}
                  onClick={handleItemFocus}
                  onTitleHeightUpdate={handleTitleHeight}
                  position={position}
                  props={chartData.matrixMap.get(position)}
                  tags={tags}
                  titleHeight={titleHeight}
                  totals={chartData.metrics}
                />
              </div>
            ))}
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={5}>
        {showEntitiesTable && (
          <EntitiesTable
            initialData={chartData?.matrixMap?.get(selected)?.data}
            metrics={chartData?.matrixMap?.get(selected)?.metrics}
            selected={chartData?.matrixMap?.get(selected)?.props}
            chartRef={ref}
            metric={metric}
            focusItem={focusItem}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default BinaryMatrix;
