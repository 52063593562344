import { useMemo } from 'react';
import { DimensionType } from '@/molecules/tableElements';
import { uniqueValueColumn } from '@/organisms/tables/utils';

const useDesignColumnDefs = ({ dimension }) => {
  const isActivity =
    dimension === DimensionType.ACTIVITY ||
    dimension === DimensionType.ACTIVITY_TAGS_ACTIVITY;

  return useMemo(() => {
    return [
      {
        field: 'design.id',
        filter: 'agMultiColumnFilter',
        hide: true,
      },
      {
        chartDataType: 'category',
        field: 'design.name',
        filter: 'agMultiColumnFilter',
      },
      isActivity && {
        chartDataType: 'series',
        field: 'design.hours',
        filter: 'agNumberColumnFilter',
        hide: true,
        headerName: 'Raw Design Hours',
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      isActivity && {
        chartDataType: 'series',
        colId: 'design.sumHours',
        filter: 'agNumberColumnFilter',
        headerName: 'Design Hours',
        valueGetter: (params) => {
          return uniqueValueColumn(params, 'design.hours', 'design.id');
        },
      },
      {
        chartDataType: 'category',
        field: 'design.scope',
        filter: 'agMultiColumnFilter',
      },
      {
        chartDataType: 'category',
        field: 'design.type',
        filter: 'agMultiColumnFilter',
      },
      {
        cellDataType: 'date',
        chartDataType: 'date',
        field: 'design.created_at',
        filter: 'agDateColumnFilter',
        headerName: 'Design Created',
        hide: true,
      },
      {
        cellDataType: 'date',
        chartDataType: 'date',
        field: 'design.updated_at',
        filter: 'agDateColumnFilter',
        headerName: 'Design Updated',
        hide: true,
      },
    ].filter((design) => design);
  }, []);
};

export default useDesignColumnDefs;
