import { FilterProperty as Property } from '@/organisms/filters/enums';

const FilterPropertyMap = new Map([
  [Property.ACTIVITY, { label: 'Activity' }],
  [Property.ASSIGNEE, { label: 'Assignee' }],
  [Property.BUDGET, { label: 'Budget' }],
  [Property.CREATED_AT, { label: 'Created' }],
  [Property.DESCRIPTION, { label: 'Description' }],
  [Property.FTE, { label: 'FTE' }],
  [Property.GROUP, { label: 'Team' }],
  [Property.HOURS, { label: 'Hours' }],
  [Property.IS_MANAGER, { label: 'Manager role' }],
  [Property.LAYER, { label: 'Reporting layer' }],
  [Property.LAYERS, { label: 'Managed layers' }],
  [Property.LEAD, { label: 'Lead' }],
  [Property.NAME, { label: 'Name' }],
  [Property.PEOPLE, { label: 'People' }],
  [Property.PROPERTY, { label: 'Property' }],
  [Property.ROLE, { label: 'Role' }],
  [Property.SKILL, { label: 'Skill' }],
  [Property.SPAN, { label: 'Span' }],
  [Property.TAG, { label: 'Tag' }],
  [Property.TITLE, { label: 'Title' }],
  [Property.UPDATED_AT, { label: 'Updated' }],
]);

export default FilterPropertyMap;
