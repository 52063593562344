import { EntityType } from '@/shared/enums';
import { Visibility } from '@/lib/enums';

export default function buildEntityOptions(
  scenario,
  snapshotEntityMap,
  entityType
) {
  const options = [];
  const unassignedPerson = { first_name: 'Unassigned' };

  if (entityType === EntityType.GROUP) {
    snapshotEntityMap?.get(EntityType.GROUP)?.forEach((group) => {
      if (group.__visibility === Visibility.NONE) {
        return;
      }

      if (scenario?.entity?.__above?.[EntityType.GROUP]?.has(group.uuid)) {
        return;
      }

      options.push({
        id: group.uuid,
        label: group.name,
        parentCount: group.__above?.[EntityType.GROUP]?.size,
      });
    });

    options.sort((a, b) => {
      return a.parentCount - b.parentCount;
    });

    options.unshift({
      id: 'ALL',
      label: 'All teams',
    });
  }

  if (entityType === EntityType.ROLE) {
    snapshotEntityMap?.get(EntityType.ROLE)?.forEach((role) => {
      if (role.__visibility === Visibility.NONE) {
        return;
      }

      options.push({
        id: role.uuid,
        label: role.title,
        person: role.__person ?? unassignedPerson,
        parentCount: role.__above?.[EntityType.ROLE]?.size,
      });
    });

    options.sort((a, b) => {
      return a.parentCount - b.parentCount;
    });

    options.unshift({
      id: 'ALL',
      label: 'All roles',
    });
  }

  if (entityType === EntityType.MANAGER) {
    snapshotEntityMap?.get(EntityType.ROLE)?.forEach((role) => {
      if (role.__visibility === Visibility.NONE || !role.is_manager) {
        return;
      }

      options.push({
        id: role.uuid,
        label: role.title,
        person: role.__person ?? unassignedPerson,
        parentCount: role.__above?.[EntityType.MANAGER]?.size,
      });
    });

    options.sort((a, b) => {
      return a.parentCount - b.parentCount;
    });

    options.unshift({
      id: 'ALL',
      label: 'All managers',
    });
  }

  if (entityType === EntityType.ACTIVITY) {
    snapshotEntityMap?.get(EntityType.ACTIVITY)?.forEach((activity) => {
      if (activity.__visibility === Visibility.NONE) {
        return;
      }

      options.push({
        id: activity.uuid,
        label: activity.__description,
      });
    });

    options.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    options.unshift({
      id: 'ALL',
      label: 'All activities',
    });
  }

  return options;
}
