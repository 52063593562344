import { Collections, Arr } from '@pkg/utils';
import reduceProperties from '../reduceProperties';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} [input.title]
 * @param {Number} [input.fte]
 * @param {Number} [input.budget]
 * @param {Number} [input.properties]
 * @param {Number} [input.skills]
 * @param {Number} [input.tags]
 * @returns {Object}
 */
export default function update(snapshot, input) {
  const entities = snapshot.entities;
  const now = Date.now();

  const role = Collections.findById(entities.roles, input.uuid);
  const roleInput = { uuid: role.uuid, updated_at: now };

  let isDifferent = false;
  let shouldCalculate = false;

  if (input.is_manager !== undefined && role.is_manager !== input.is_manager) {
    roleInput.is_manager = input.is_manager;
    isDifferent = true;
  }

  if (input.title !== undefined && role.title !== input.title) {
    roleInput.title = input.title;
    isDifferent = true;
  }

  if (input.fte !== undefined && role.fte !== input.fte) {
    roleInput.fte = input.fte;
    isDifferent = true;
    shouldCalculate = true;
  }

  if (input.budget !== undefined && role.budget !== input.budget) {
    roleInput.budget = input.budget;
    isDifferent = true;
    shouldCalculate = false;
  }

  if (Array.isArray(input.tags) && Arr.diff(role.tags, input.tags).length > 0) {
    roleInput.tags = input.tags;
    isDifferent = true;
  }

  if (
    Array.isArray(input.skills) &&
    Arr.objectDiff(role.skills, input.skills).length > 0
  ) {
    roleInput.skills = input.skills;
    isDifferent = true;
  }

  /** @todo convert to its own reducer and explicitly create/update/remove */
  if (Array.isArray(input.properties)) {
    const propertiesInput = reduceProperties(role, input.properties);

    if (propertiesInput) {
      roleInput.properties = propertiesInput;
      isDifferent = true;
    }
  }

  if (!isDifferent) {
    return null;
  }

  const mutation = {
    created_at: now,
    entities: {
      roles: {
        update: [roleInput],
      },
    },
  };

  if (shouldCalculate) {
    mutation.entities.roles.calculate = [roleInput.uuid];
  }

  return mutation;
}
