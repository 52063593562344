import { isEqual } from '@pkg/utils/objects';
import { Comparator, AssigneeStatus } from '@/lib/enums';

const COMPARATORS = {
  [Comparator.STARTS_WITH]: (identifierValue, compareValue) =>
    identifierValue.toLowerCase().startsWith(compareValue.toLowerCase()),

  [Comparator.CONTAINS]: (identifierValue, compareValue) =>
    identifierValue.toLowerCase().indexOf(compareValue.toLowerCase()) !== -1,

  [Comparator.CONTAINS_ANY]: (identifierValue, compareValue) => {
    const a = identifierValue?.toLowerCase();
    return compareValue.some((value) => {
      const b = value?.toLowerCase();
      return a === b || a?.indexOf(b) >= 0;
    });
  },

  [Comparator.CONTAINS_ALL]: (identifierValue, compareValue) => {
    const a = identifierValue?.toLowerCase();
    return compareValue.every((value) => {
      const b = value?.toLowerCase();
      return a === b || a?.indexOf(b) >= 0;
    });
  },

  [Comparator.HAS_ITEM_ANY]: (identifierValue, compareValue) => {
    const value = Array.isArray(identifierValue)
      ? identifierValue
      : [identifierValue];
    return value.some((r) => compareValue.indexOf(r) >= 0);
  },

  [Comparator.HAS_ITEM_ALL]: (identifierValue, compareValue) =>
    compareValue.every((v) => identifierValue.includes(v)),

  [Comparator.EXCLUDE_ANY]: (identifierValue, compareValue) => {
    const value = Array.isArray(identifierValue)
      ? identifierValue
      : [identifierValue];

    return !value.some((r) => compareValue.indexOf(r) >= 0);
  },

  [Comparator.EXCLUDE_ALL]: (identifierValue, compareValue) =>
    compareValue.some((v) => !identifierValue.includes(v)),

  [Comparator.HAS_OBJECT_ANY]: (identifierValue, compareValue) =>
    identifierValue.some((r) => compareValue.some((v) => isEqual(r, v, false))),

  [Comparator.HAS_OBJECT_ALL]: (identifierValue, compareValue) =>
    compareValue.every((v) =>
      identifierValue.some((r) => isEqual(r, v, false))
    ),

  [Comparator.EXCLUDE_ANY_OBJECT]: (identifierValue, compareValue) =>
    !identifierValue.some((r) =>
      compareValue.some((v) => isEqual(r, v, false))
    ),

  [Comparator.EXCLUDE_ALL_OBJECT]: (identifierValue, compareValue) =>
    compareValue.some(
      (v) => !identifierValue.some((r) => isEqual(r, v, false))
    ),

  [Comparator.IS]: (identifierValue, compareValue) => {
    return identifierValue === compareValue;
  },

  [Comparator.IS_ONE_OF]: (identifierValue, compareValue) => {
    return compareValue.includes(identifierValue);
  },

  [Comparator.IS_NONE_OF]: (identifierValue, compareValue) => {
    return !compareValue.includes(identifierValue);
  },

  [Comparator.ENTITY_IS_ANY]: (identifierValue, compareValue) => {
    return compareValue.some((v) => v.uuid === identifierValue);
  },

  [Comparator.ENTITY_IS_ANY_NOT]: (identifierValue, compareValue) =>
    !compareValue.some((v) => v.uuid === identifierValue),

  [Comparator.PERSON_IS]: (identifierValue, compareValue) =>
    (compareValue.status === AssigneeStatus.ASSIGNED &&
      identifierValue !== null) ||
    (compareValue.status === AssigneeStatus.UNASSIGNED &&
      identifierValue === null) ||
    (compareValue.status === AssigneeStatus.PERSON &&
      identifierValue === compareValue.person?.uuid),

  [Comparator.EQUALS]: (identifierValue, compareValue) =>
    identifierValue == compareValue,

  [Comparator.LESS_THAN]: (identifierValue, compareValue) =>
    identifierValue !== null && Number(identifierValue) < Number(compareValue),

  [Comparator.LESS_THAN_OR_EQUAL]: (identifierValue, compareValue) =>
    identifierValue !== null && Number(identifierValue) <= Number(compareValue),

  [Comparator.GREATER_THAN]: (identifierValue, compareValue) =>
    identifierValue !== null && Number(identifierValue) > Number(compareValue),

  [Comparator.GREATER_THAN_OR_EQUAL]: (identifierValue, compareValue) =>
    identifierValue !== null && Number(identifierValue) >= Number(compareValue),
};

export const compare = (relativeKey, identifierValue, compareValue) => {
  if (identifierValue === undefined) {
    if (relativeKey === Comparator.IS_NONE_OF) {
      return true;
    }
    return false;
  }

  const comparatorFunction = COMPARATORS[relativeKey];
  return comparatorFunction(identifierValue, compareValue);
};
