import { ActivityOwner, EntitySource, LibrarySection } from './enums';

const CDN_DOMAIN = 'https://cdn.beamible.com';
const POPPINS_FONT =
  'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap';

export default {
  FONTS: {
    POPPINS: POPPINS_FONT,
  },
  MAX_ACTIVITIES: 12,
  MAX_ACTIVITY_HOURS: 999,
  MAX_FTE: 100,
  MAX_BUDGET: 999999999,
  MAX_SALARY: 99999999,
  DEFAULT_FTE: 1.0,
  FTE_HOURS_PER_WEEK: 38,
  HISTORY_LIMIT: 25,
  SEARCH_LIMIT: 8,
  DEFAULT_GROUP_NAME: 'Untitled team',
  DEFAULT_ROLE_TITLE: 'Untitled role',
  DEFAULT_ACTIVITY_DESCRIPTION: 'New activity',
  DEFAULT_SKILL_DESCRIPTION: 'New skill',
  DEFAULT_TAG_NAME: 'New tag',
  VALUE_LENGTH: {
    MIN: {
      PASSWORD: 8,
      FLOW_TEMPLATE_NAME: 3,
    },
    MAX: {
      ACTIVITY_DESCRIPTION: 240,
      DESIGN_NAME: 240,
      DESIGN_GOAL: 240,
      EMAIL: 240,
      FLOW_TEMPLATE_NAME: 240,
      NAME: 48,
      OBJECTIVE: 140,
      PASSWORD: 128,
      PROPERTY_NAME: 240,
      ROLE_TITLE: 240,
      SKILL_NAME: 240,
      TAG: 240,
      TEAM_NAME: 240,
      TEMPLATE_NAME: 240,
    },
    DISPLAY: {
      CHART: 40,
      CRUMB: 40,
      OBJECTIVE: 80,
      TAG: 32,
      SKILL: 32,
    },
  },
  INSIGHTS: {
    CAPACITY_THRESHOLD: 1.1,
    CATEGORIES: {
      PRODUCTIVITY: 'Productivity',
      WORK_HEALTH: 'Work Health',
    },
    METRICS: {
      STRATEGIC_IMPORTANCE: 'Strategic Importance',
      ADMINISTRATIVE: 'Administrative',
      INTERNAL_MEETINGS: 'Internal Meetings',
      ENERGISING: 'Energising',
      WORKLOAD: 'Workload',
      OVER_CAPACITY: 'Over Capacity',
    },
    TAGS: {
      STRATEGIC_IMPORTANCE: 'BtMCuemqtmp4KGzaArqr1',
      SPECIALIST_CAPABILITY: 'BtMCuemqtmp4KGzaArqr2',
      ENERGISING: 'BtMCuemqtmp4KGzaArqr3',
      DEENERGISING: 'BtMCuemqtmp4KGzaArqr4',
      INTERNAL_MEETINGS: 'BtMCuemqtmp4KGzaArqr5',
      EXTERNAL_MEETINGS: 'BtMCuemqtmp4KGzaArqr6',
      ADMINISTRATIVE: 'BtMCuemqtmp4KGzaArqr7',
      EFFICIENCY_OPPORTUNITY: 'CBHQKzrwvcVHKdyd5sYtw',
      CRITICAL_WORK: 'CBHQ151cNafXumznkbDxj',
    },
  },
  PATHS: {
    DASHBOARD: {
      index: '/dashboard',
    },
    DESIGN: {
      main: '/d/main/latest',
      scenarios: '/scenarios',
    },
    ROLE: {
      main: '/role',
      comparison: '/compare',
    },
    TEAMS: {
      list: '/teams',
      create: '/teams/create',
    },
    SCENARIOS: {
      list: '/teams/scenarios',
      create: '/teams/scenarios/create',
    },
    SHARED_SCENARIOS: {
      list: '/teams/shared',
    },
    ACCOUNT: {
      index: '/accounts',
      people: '/accounts/people',
      import: '/accounts/import',
      invites: '/accounts/invites',
      tags: '/accounts/tags',
      templates: '/accounts/templates',
      integrations: '/accounts/integrations',
      settings: '/accounts/settings',
    },
    LIBRARY: {
      index: '/library/activities',
      [LibrarySection.ACTIVITIES]: '/library/activities',
      [LibrarySection.FLOW_TEMPLATES]: '/library/flow_templates',
      [LibrarySection.PROPERTIES]: '/library/properties',
      [LibrarySection.ROLES]: '/library/roles',
      [LibrarySection.SKILLS]: '/library/skills',
      [LibrarySection.TAGS]: '/library/tags',
    },
  },
  LINKS: {
    CONTACT: 'https://beamible.com/contact/',
    PRIVACY: 'https://beamible.com/privacy-policy/',
    TERMS: 'https://beamible.com/terms-conditions/',
  },
  INDUSTRIES: [
    ['ACCOUNTING', 'Accounting'],
    ['ADMINISTRATION_OFFICE_SUPPORT', 'Administration & Office Support'],
    ['ADVERTISING_ARTS_MEDIA', 'Advertising, Arts & Media'],
    ['BANKING_FINANCIAL_SERVICES', 'Banking & Financial Services'],
    ['CALL_CENTRE_CUSTOMER_SERVICE', 'Call Centre & Customer Service'],
    ['CEO_GENERAL_MANAGEMENT', 'CEO & General Management'],
    ['COMMUNITY_SERVICES_DEVELOPMENT', 'Community Services & Development'],
    ['CONSTRUCTION', 'Construction'],
    ['CONSULTING_STRATEGY', 'Consulting & Strategy'],
    ['DESIGN_ARCHITECTURE', 'Team & Architecture'],
    ['EDUCATION_TRAINING', 'Education & Training'],
    ['ENGINEERING', 'Engineering'],
    ['FARMING_ANIMALS_CONSERVATION', 'Farming, Animals & Conservation'],
    ['GOVERNMENT_DEFENCE', 'Government & Defence'],
    ['HEALTHCARE_MEDICAL', 'Healthcare & Medical'],
    ['HOSPITALITY_TOURISM', 'Hospitality & Tourism'],
    ['HUMAN_RESOURCES_RECRUITMENT', 'Human Resources & Recruitment'],
    [
      'INFORMATION_COMMUNICATION_TECHNOLOGY',
      'Information & Communication Technology',
    ],
    ['INSURANCE_SUPERANNUATION', 'Insurance & Superannuation'],
    ['LEGAL', 'Legal'],
    [
      'MANUFACTURING_TRANSPORT_LOGISTICS',
      'Manufacturing, Transport & Logistics',
    ],
    ['MARKETING_COMMUNICATIONS', 'Marketing & Communications'],
    ['MINING_RESOURCES_ENERGY', 'Mining, Resources & Energy'],
    ['REAL_ESTATE_PROPERTY', 'Real Estate & Property'],
    ['RETAIL_CONSUMER_PRODUCTS', 'Retail & Consumer Products'],
    ['SALES', 'Sales'],
    ['SCIENCE_TECHNOLOGY', 'Science & Technology'],
    ['SELF_EMPLOYMENT', 'Self Employment'],
    ['SPORT_RECREATION', 'Sport & Recreation'],
    ['TRADES_SERVICES', 'Trades & Services'],
  ],
  INTEGRATION: {
    EXTERNAL_SYNCING: 'EXTERNAL_SYNCING',
    READY: 'IMPORT_READY',
    PENDING: 'IMPORT_PENDING',
    SUCCESS: 'IMPORT_SUCCESS',
    FAILED: 'IMPORT_FAILED',
  },
  VISIBILITY: {
    NONE: 'NONE',
    FULL: 'FULL',
  },
  VISIBILITY_OPACITY: {
    NONE: 0.4,
    FULL: 1.0,
  },
  DEFAULT_ENTITIES: {
    groups: [],
    roles: [],
    activities: [],
    people: [],
    skills: [],
  },
  DEFAULT_MUTATION: {
    created_at: null,
    name: null,
    objective: null,
    entities: {
      groups: {
        create: [],
        update: [],
        remove: [],
      },
      roles: {
        calculate: [],
        create: [],
        update: [],
        remove: [],
      },
      activities: {
        create: [],
        update: [],
        remove: [],
      },
    },
  },
  DEFAULT_SNAPSHOT: {
    name: null,
    objective: null,
    created_at: null,
    entities: {
      groups: [],
      roles: [],
      activities: [],
      people: [],
    },
  },
  DEFAULT_GROUP: {
    uuid: null,
    group_uuid: null,
    lead_uuid: null,
    name: null,
    objective: null,
    properties: [],
    tags: [],
    order: Infinity,
    created_at: null,
    updated_at: null,
  },
  DEFAULT_ROLE: {
    uuid: null,
    group_uuid: null,
    parent_uuid: null,
    user_uuid: null,
    is_manager: false,
    title: 'Untitled role',
    fte: 1.0,
    budget: 0,
    properties: [],
    skills: [],
    tags: [],
    order: Infinity,
    created_at: null,
    updated_at: null,
  },
  DEFAULT_ACTIVITY: {
    uuid: null,
    library_uuid: null,
    owner_uuid: null,
    owner_type: ActivityOwner.ROLE,
    source_uuid: null,
    source_type: EntitySource.NONE,
    hours: 0,
    properties: [],
    tags: [],
    order: Infinity,
    created_at: null,
    disabled_at: null,
    updated_at: null,
  },
  DIAGRAM_SELECTED_SCALE_MAX: 1,
  DIAGRAM_SELECTED_SCALE_MIN: 0.8,
  CDN: CDN_DOMAIN,
  UPLOADS_HOST: 'https://uploads-jlofeujcta-ts.a.run.app',
  PUSHER_CLUSTER: 'ap4',
  IMPORT: {
    TEMPLATE: `${CDN_DOMAIN}/statics/templates/import.xlsx`,
    STATUS: {
      FAILED: 'FAILED',
      PENDING: 'PENDING',
      SUCCESS: 'SUCCESS',
    },
  },
  WEEKS_PER_MONTH: 4.345,
  MONTHS_PER_YEAR: 12,
};
