const ScopeType = Object.freeze({
  ALL: 'ALL',
  ACTIVITIES: 'ACTIVITIES',
  ALIGNMENT: 'ALIGNMENT',
  GROUPS: 'GROUPS',
  ROLES: 'ROLES',
  PRIORITISATION: 'PRIORITISATION',
  SKILLS: 'SKILLS',
  SUMMARY: 'SUMMARY',
  TAGS: 'TAGS',
});

export default ScopeType;
