import { Paragraph } from '@/atoms/typography';

const TagActivityInsightInformation = () => {
  return (
    <Paragraph>
      Here is some information for the tagged activity insights.
    </Paragraph>
  );
};

export default TagActivityInsightInformation;
