import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { CategorisedActivityList } from '@/organisms/activities';
import { ActivityCardList } from '@/organisms/cards';
import { RoleActivityHoursChart } from '@/organisms/charts';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ActivityHours = ({ allowedTagSet, coreActivitySet, categoryTagMap }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const categoryTags = categoryTagMap ? [...categoryTagMap.keys()] : [];

  const settings = {
    isEditable: true,
    isTagsEditable: false,
    showComparison: false,
    showDragDrop: false,
    showHours: true,
    showMenu: false,
    showPercentage: true,
    showRemove: true,
    showTags: false,
    showTitle: !categoryTagMap?.size,
    showTotalActivityHours: true,
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paragraph size={isLargeScreen ? Size.LARGE : Size.MEDIUM}>
            Enter hours for each activity to reflect{' '}
            <span style={{ textDecoration: 'underline' }}>approximately</span>{' '}
            how much time you spend on (on average) per week.
          </Paragraph>
          <Paragraph
            size={isLargeScreen ? Size.MEDIUM : Size.SMALL}
            overrideStyles={{ fontStyle: 'italic', mt: 2 }}
          >
            Tip: Check your total hours add up to your typical work week.
          </Paragraph>
        </Grid>
        <Grid item xs={12} md={8} sx={{ mt: 1 }}>
          {categoryTags?.length ? (
            <CategorisedActivityList
              categoryTags={categoryTags}
              categoryTagMap={categoryTagMap}
              Component={
                <ActivityCardList
                  allowedTagSet={allowedTagSet}
                  coreActivitySet={coreActivitySet}
                />
              }
              settings={settings}
            />
          ) : (
            <ActivityCardList
              allowedTagSet={allowedTagSet}
              coreActivitySet={coreActivitySet}
              settings={settings}
            />
          )}
        </Grid>
      </Grid>
      {isLargeScreen && (
        <Box sx={{ position: 'fixed', right: 'calc(12vw)', top: 'calc(37vh)' }}>
          <RoleActivityHoursChart />
        </Box>
      )}
    </>
  );
};

export default ActivityHours;
